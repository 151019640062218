import { configureStore } from "@reduxjs/toolkit";

import {
  chatReducer,
  userReducer,
  callReducer,
  tempChatDataReducer,
  groupCallReducesr,
  allMessagesReducer,
  allGroupMessagesReducer,
} from "./reducers/userReducer";

const store = configureStore({
  reducer: {
    reduxAllGroupMessages: allGroupMessagesReducer,
    tempChatWindowData: tempChatDataReducer,
    reduxAllMessages: allMessagesReducer,
    currentGroupCall: groupCallReducesr,
    currentCall: callReducer,
    currentChat: chatReducer,
    user: userReducer,
  },
});

// const persistConfig = {
//   key: "reduxAllGroupMessages",
//   storage,
//   // Specify the reducers you want to persist
//   whitelist: ["allGroupMessagesReducer"], // In this example, we persist the 'user' reducer
// };
export default store;

// export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
