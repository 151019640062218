/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../../redux/Hooks";
import { getUsers, removeUserFromGroup } from "../../../_utils/firebase";
import { capitalizeWords } from "../../../_utils";

export const GroupMemberList = (props: any) => {
  const [groupList, setGroupList] = useState([]);
  const { uid, name, photo, userType, currentChat, groupUsers } =
    useAppSelector((state) => state.currentChat);

  useEffect(() => {
    let arr = [];
    groupUsers &&
      Object.keys(groupUsers).map((element, index) => {
        getUsers(element).then((response) => {
          arr.push(response);
          arr[index].id = element;
        });
      });
    setGroupList(arr);
  }, []);
  // console.log(groupList);

  return (
    <Modal
      className="group-member-list gropu-detail-modal"
      show={props?.isShow}
      centered
    >
      <Modal.Header>
        <span
          className="close_butn"
          style={{ cursor: "pointer" }}
          onClick={props?.closeModal}
        >
          Close
        </span>
        <div className="group-detail text-center w-100">
          <img
            src={
              photo
                ? photo
                : "https://firebasestorage.googleapis.com/v0/b/nzchat.appspot.com/o/default_user_img.png?alt=media&token=3b78244f-fa1c-42fe-b289-0e1c9923b043"
            }
            width={80}
            height={80}
          />
          <Modal.Title className="mt-2 text-white">
            {capitalizeWords(name)}
          </Modal.Title>
        </div>
      </Modal.Header>
      {groupList && (
        <Modal.Body>
          <ul className="user-list">
            {groupList &&
              groupList?.map((element, index) => {
                return (
                  <li key={index}>
                    <img src={element?.photo} width={80} height={80} />
                    <div className="user-information">
                      <p> {element.name} </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
      )}
    </Modal>
  );
};
