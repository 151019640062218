import React from "react";
import Skeleton from "react-loading-skeleton";

export const MessageSekelton = () => {
  return (
    <>
      <>
        <div className="d-flex  justify-content-end">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-start">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-end">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-start">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-end">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-start">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-end">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-start">
          <Skeleton height={70} width={300} />
        </div>
        <div className="d-flex  justify-content-end">
          <Skeleton height={70} width={300} />
        </div>
      </>
    </>
  );
};
