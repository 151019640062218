export const CALL_STATUS = {
    Ringing: "ringing",
    Disconnect: "disconnect",
    Answered: "answered",
    Cancelled: "cancelled"
}
export const CALL_TYPE = {
    Incoming: "incoming",
    Outgoing: "outgoing"
}

export const CALL_NATURE = {
    Individual: "user",
    Group: "group"
}
export const TYPE = {
    VIDEO_CALL_INDIVIDUAL: 2,
    VIDEO_CALL_GROUP: 4,
    AUDIO_CALL_INDIVIDUAL: 1,
    AUDIO_CALL_GROUP: 3,
}