import AgoraRTC from "agora-rtc-react";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../redux/Hooks";
import { useAudioCallContext } from "../_contexts/AudioCallContext";
import {
  clearCallDetails,
  createIncomingCall,
  createOutgoingCall,
  setAnsweredCall,
  setCurrentOutgoingCallId,
} from "../redux/actions/user";
import { CALL_STATUS } from "../_utils";
const ConnectAudioCall = () => {
  const APPID = "fe59ad8be52e430eaacc2da8f60d3cac";
  const dispatch = useAppDispatch();

  const [isToggled, setToggled] = useState(false);
  const [totalUsers, setTotalusers] = useState(0);
  const [ismute, setismute] = useState(true);

  const handleClick = () => {
    setToggled(!isToggled);
  };

  const CLEAR_CALL_DATA = {
    toId: "",
    callerId: "",
    callId: "",
    name: "",
    photo: "",
    channel: "",
    type: "",
    timestamp: "",
    status: "",
    callType: "",
    phoneNumber: "",
    callNature: "",
  };

  const buttonClass1 = isToggled
    ? "meet_main_content open"
    : "meet_main_content";
  const buttonClass2 = isToggled
    ? "meet_sidebar_wrapper open"
    : "meet_sidebar_wrapper";
  const { handelEndIncomingVoiceCallToFriebase } = useAudioCallContext();
  const { status, callType, channel, name, photo } = useAppSelector(
    (state) => state.currentCall
  );


 
  const userImg =
    "https://firebasestorage.googleapis.com/v0/b/nzchat.appspot.com/o/default_user_img.png?alt=media&token=3b78244f-fa1c-42fe-b289-0e1c9923b043";

  useEffect(() => {
    joinCall();
    // dispatch(
    //   setAnsweredCall({
    //     status: CALL_STATUS.Cancelled,
    //     callNature: "",
    //   })
    // );
    // dispatch(clearCallDetails(CLEAR_CALL_DATA));

    if (!channel) {
      handelEndIncomingVoiceCallToFriebase();
    }
  }, []);

  let localUid;

  var client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  var options = {
    appid: APPID,
    channel: null,
    uid: null,
    token: null,
  };

  AgoraRTC.onAutoplayFailed = () => {
    alert("click to start autoplay!");
  };

  var localTracks = {
    audioTrack: null,
  };

  var remoteUsers = {};

  async function subscribe(user, mediaType) {
    const uid = user.uid;
    // subscribe to a remote user
    await client.subscribe(user, mediaType);
   
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }

  async function leave() {
    // eslint-disable-next-line no-undef
    
    for (let trackName in localTracks) {
      // eslint-disable-next-line no-undef
      var track = localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        // eslint-disable-next-line no-undef
        localTracks[trackName] = undefined;
      }
    }

    // Remove remote users and player views.
    remoteUsers = {};
    // document.querySelector("#remote-playerlist").html("");

    // leave the channel
    //  if(await client.leave()) {
    //
    //  }

    await client.leave();
    // document.querySelector("#local-player-name").text("");
    // $("#joined-setup").css("display", "none");
   
    handelEndIncomingVoiceCallToFriebase();
    window.location.href = "/chat";
  }

  function handleUserUnpublished(user, mediaType) {
    if (mediaType === "audio") {
      const id = user.uid;
      delete remoteUsers[id];
      // $(`#player-wrapper-${id}`).remove();
    }
  }

  async function join() {
    // Add an event listener to play remote tracks when remote user publishes.
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);

    // Join a channel and create local tracks. Best practice is to use Promise.all and run them concurrently.
    [options.uid, localTracks.audioTrack] = await Promise.all([
      // Join the channel.
      client.join(
        options.appid,
        options.channel,
        options.token || null,
        options.uid || null
      ),
      // Create tracks to the local microphone and camera.
      AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "music_standard",
      }),
    ]);
    // $("#joined-setup").css("display", "flex");
    // Publish the local audio tracks to the channel.
    await client.publish(Object.values(localTracks));
  
  }

  const joinCall = async () => {
    options.channel = channel;

    await join();
  };

  let numberOfUsers = 0;
  const [users, setUsers] = useState([])
  useEffect(() => {
    
  }, [users]);
  // Set up event listeners
  client.on("user-joined", (uid) => {
    // A user has joined the channel
    numberOfUsers++;
    localUid = uid;
    // Create a new array with the updated element
    const newArray = [...users];

    setUsers((prevArray) => [...prevArray, 1]);
    // const newArray = [...users, 1];
    // Update the state with the new array
    // setUsers(users);
   
  });


 

  client.on("user-left", (uid) => {
    // A user has left the channel
    numberOfUsers--;
    users.push(numberOfUsers);

    
  });
  const localAudioTrack = AgoraRTC.createMicrophoneAudioTrack();

  function toggleLocalAudio(mute) {
    // mute function will go here 
  }


  return (
    <>
      <section className="meet_wrapper_container">
        <div className="meet_inner_container">
          <div className={buttonClass1}>
            <div className="meet_grid_container">
              {users && users?.map(() => {
                return (
                  <div className="meet_grid_item">
                    <img src={photo ? photo : userImg} alt="" />
                    <span className="meet_user_name">{name}</span>
                    {/* <span className="meet_volume_icon">
                    <i className="ri-volume-up-line"></i>
                  </span> */}
                  </div>
                );
              })}

            </div>
          </div>
          <div className="meet_main_bottom-bar">
            <div className="meet_icon-box_center">
              {ismute && (
                <span
                  className="icon__box"
                  onClick={() => {
                    toggleLocalAudio(true); // Mute audio
                  }}
                >
                  <i className="ri-mic-fill"></i>
                </span>
              )}
              {!ismute && (
                <span
                  onClick={() => {
                    toggleLocalAudio(localUid, false); // Mute audio
                  }}
                  className="icon__box"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1024"
                    height="1024"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="currentColor"
                      d="M480 704h160a64 64 0 0 0 64-64v-32h-96a32 32 0 0 1 0-64h96v-96h-96a32 32 0 0 1 0-64h96v-96h-96a32 32 0 0 1 0-64h96v-32a64 64 0 0 0-64-64H384a64 64 0 0 0-64 64v32h96a32 32 0 0 1 0 64h-96v96h96a32 32 0 0 1 0 64h-96v96h96a32 32 0 0 1 0 64h-96v32a64 64 0 0 0 64 64zm64 64v128h192a32 32 0 1 1 0 64H288a32 32 0 1 1 0-64h192V768h-96a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64h256a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128z"
                    />
                  </svg>
                </span>
              )}
              <span className="icon__box call-icon">
                <i className="ri-phone-line" onClick={leave}></i>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConnectAudioCall;
