import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getDatabase, ref, get, child, onValue } from "firebase/database";
import {
  getDatabase,
  child,
  onValue,
  update,
  push,
  set,
  limitToLast,
  remove,

} from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  get,
  equalTo,

} from "firebase/database";
import { firebaseConfig } from "../config/config";
import {
  getStorage,
  ref as sref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
// auth.signOut();

export const database = getDatabase(app);
export const storage = getStorage(app);
export const messaging = getMessaging(app);
// const starsRef = ref(storage, 'images/stars.jpg');

export const getFileFromFireStorage = async (path: any) => {
  try {
    let url = await getDownloadURL(sref(storage, path));
    return url;
  } catch (error) {
    throw error;
  }
};

export const getAllContacts = async (uid: any) => {
  try {
    const userContactsRef = ref(database, `userContacts/${uid}`);
    const snapshot = await get(userContactsRef);

    if (snapshot.exists()) {
     
      let users = [];
      snapshot.val().forEach(async (user: any) => {
        if (user.user_id) {
          const userRef = ref(database, `users/${user.user_id}`);
          const userSnapshot = await get(userRef);
          if (userSnapshot.exists()) {
            let userData = {
              userId: user.user_id,
              photo: userSnapshot.val().photo,
              userName: userSnapshot.val().userName,
              isInChat: user.isInChat,
            };
          
            users.push(userData);
            // return userSnapshot.val();
          }
        }
      });
    
      return users;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

// export const getLastMessage = async(userId:any, chatUserId:any) =>{
//   try {
//     const messagesRef = ref(database, "messages");
//   } catch (error) {
//     throw error
//   }
// }
export const getUserMessages = async (userId: any, init:any) => {
  try {
    let allMsg = [];
    const messages: any = [];

    const messagesRef = ref(database, "messages");
    onValue(messagesRef, (snapshot) => {
     

      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          const messageKey = childSnapshot.key;
          const message = childSnapshot.val();
          allMsg.push(message);
          if (message.fromId === userId) {
            if (!messages[message.toId]) messages[message.toId] = [];
            messages[message.toId].push({ key: messageKey, ...message });
          } else if (
            message.toId === userId
          ) {
            if (!messages[message.fromId]) messages[message.fromId] = [];
            messages[message.fromId].push({ key: messageKey, ...message });
          }
        });
      } else {
   
      }
init(messages)
    });

    // const snapshot = await get(messagesRef);
    // if (snapshot.exists()) {
    //   snapshot.forEach((childSnapshot) => {
    //     const messageKey = childSnapshot.key;
    //     const message = childSnapshot.val();
    //     allMsg.push(message);
    //     // if (message) dispatch(setAllMessages([{...message}]));
    //     // Check if the userId matches toId or fromId, and currentChaterId matches the opposite
    //     if (
    //       message.fromId === userId
    //       // (message.toId === userId || message.fromId === userId) &&
    //       // (message.toId === currentChaterId && message.fromId === userId)
    //     ) {
    //       if (!messages[message.toId]) messages[message.toId] = [];
    //       messages[message.toId].push({ key: messageKey, ...message });
    //     } else if (
    //       message.toId === userId
    //       // (message.toId === userId || message.fromId === userId) &&
    //       // (message.toId === userId && message.fromId === currentChaterId)
    //     ) {
    //       if (!messages[message.fromId]) messages[message.fromId] = [];
    //       messages[message.fromId].push({ key: messageKey, ...message });
    //     }
    //   });

    // } else {
 
    // }

    // return messages
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

export const getAllGroupMessages = async (userId: any) => {
  try {
    let groupMessages: any = {};
    let groups = await getGroups(userId);
    if (groups.length > 0) {
      let messages = await getGroupMessages(groups[0].key);
      groupMessages[groups[0].key] = messages;
    }
    return groupMessages;
  } catch (error) {
    throw error;
  }
};

export const authenticateFirebase = async (token: any) => {
  await signInWithCustomToken(auth, token)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
   
    })
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      throw error;
    });
};

export const getUsers = async (userId: any) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
    
      return snapshot.val();
    } else {
     
      return null;
    }
  } catch (error) {
    // console.error("---------------------------Error:", error);
    throw error;
  }
};

export const sendMessage = async (message: any) => {
  try {
    const messagesRef = ref(database, "messages");
    const newMessageRef = await push(messagesRef);
  
    const messageKey = newMessageRef.key;
    await set(newMessageRef, message);

    
    return messageKey;
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

export const sendGroupMessage = async (groupId: any, message: any) => {
  try {
    const messagesRef = ref(database, `groupsMessages/${groupId}`);
    const newMessageRef = await push(messagesRef);
    const messageKey = newMessageRef.key;

    await set(newMessageRef, message);

    return messageKey;
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

export const handleCreateVideoCall = (userId: any, callObject: any) => {
 

  //  const userCallRef = ref(database, `userCalls/${uid}`);
  //  const handleChildAdded = (snapshot: any) => {
  //    const incomingCall = snapshot.val();
  //    setIncomingCallObjectHolder(incomingCall);
  //  };
  //  onChildAdded(userCallRef, handleChildAdded);
  //  return () => {
  //    off(userCallRef, "child_added");
  //  };
};

export const getContacts = async (userId: any) => {
  try {
    const messagesRef = ref(database, "messages");
    const snapshot = await get(messagesRef);

    const contacts: any = [];

    if (snapshot.exists()) {
      const latestMessages = new Map(); // Map to store the latest message for each contact

      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();

        if (message.toId === userId || message.fromId === userId) {
          const contactId =
            message.toId === userId ? message.fromId : message.toId;

          if (!contacts.some((contact: any) => contact.userId === contactId)) {
            contacts.push({ userId: contactId, type: "user" });
          }
          // Check if this message is the latest for the contact
          if (
            !latestMessages.has(contactId) ||
            message.timestamp > latestMessages.get(contactId).timestamp
          ) {
            latestMessages.set(contactId, {
              timestamp: message.timestamp,
              content: message.content,
            });
          }
        }
      });

      // Fetch user details for each contact
      const contactPromises = contacts.map(async (contact: any) => {
        const userRef = ref(database, `users/${contact.userId}`);
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          contact.info = userSnapshot.val();
        }

        // Add the latest message data
        const latestMessage = latestMessages.get(contact.userId);
        if (latestMessage) {
          contact.latestMessage = latestMessage;
        }

        return contact;
      });

      await Promise.all(contactPromises);

      // Sort contacts by the timestamp of the latest message (newest first)
      contacts.sort(
        (a: any, b: any) =>
          b.latestMessage.timestamp - a.latestMessage.timestamp
      );
    } else {
      
    }

    return contacts;
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

export const getGroups = async (userId: any) => {
  try {
    const groupsRef = ref(database, "groups");
    const groupsSnapshot = await get(groupsRef);
    const groups: any = [];

    if (groupsSnapshot.exists()) {
      groupsSnapshot.forEach((groupSnapshot) => {
        const groupData = groupSnapshot.val();

        // Check if the user is present in the group
        if (
          groupData.users &&
          (groupData.users[userId] === true ||
            groupData.users[userId] === false)
        ) {
          // if (groupData.users && groupData.users[userId] === true) {
          // If the user is present, add the group info and key to the result
          groups.push({
            key: groupSnapshot.key,
            info: groupData.info,
            users: groupData.users,
            type: "group",
          });
        }
      });
    }
   
    return groups;
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

export const addUserToGroup = async (groupId: any, userId: any) => {
  try {
    const groupRef = ref(database, `groups/${groupId}/users`);
    const groupSnapshot = await get(groupRef);
    if (groupSnapshot.exists()) {
      const groupData = groupSnapshot.val();
      // Check if the user is not already in the group
      if (groupData[userId] === undefined) {
        // Add the user to the group
        await update(groupRef, {
          [`${userId}`]: false,
        });
       
      } else {
       
      }
    } else {
    
    }
  } catch (error) {
    // console.error("Error adding user to group:", error);
    throw error;
  }
};

export const removeUserFromGroup = async (groupId: any, userId: any) => {
  try {
    const groupRef = ref(database, `groups/${groupId}/users/${userId}`);
    const userSnapshot = await get(groupRef);
    if (userSnapshot.exists()) {
      // Remove the user from the group
      await remove(groupRef);
      
    } else {
  
    }
  } catch (error) {
    // console.error("Error removing user from group:", error);
    throw error;
  }
};
// export const getSessionInfo = (sessionId, callback) => {
//     try {
//       const sesRef = ref(database, `webSessions/${sessionId}`);
//       // Subscribe to real-time updates for the specific session
//       const unsubscribe = onValue(sesRef, (snapshot) => {
//       
//         if (snapshot.exists()) {
//           const sessionInfo = snapshot.val();
//         
//           callback(sessionInfo);
//         } else {
//           callback(null); // Session does not exist
//         }
//       });

//       // Return the unsubscribe function so you can stop listening to updates when needed
//       return unsubscribe;
//     } catch (error) {
//     
//       throw error;
//     }
//   };

export const getGroupMessages = async (groupId: any) => {
  try {
    const groupMessagesRef = ref(database, `groupsMessages/${groupId}`);
    const snapshot = await get(groupMessagesRef);

    const groupMessages: any = [];

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        const messageId = childSnapshot.key;
        const messageData = childSnapshot.val();

        // Assuming your messages have a structure like { text: 'Message text', sender: 'Sender ID', timestamp: 'Timestamp' }
        const message = {
          key: messageId,
          ...messageData,
        };

        groupMessages.push(message);
      });

      return groupMessages;
    } else {
    
      return [];
    }
  } catch (error) {
    // console.error("Error:", error);
    throw error;
  }
};

// export const userLastMessage = async (userId, currentChaterId) => {
//   try {
//     const messagesRef = ref(database, "messages");
//     const snapshot = await get(messagesRef);

//     const messages = [];

//     if (snapshot.exists()) {
//       snapshot.forEach((childSnapshot) => {
//         const message = childSnapshot.val();

//         if (
//           (message.toId === userId || message.fromId === userId) &&
//           ((message.toId === currentChaterId && message.fromId === userId) ||
//             (message.toId === userId && message.fromId === currentChaterId))
//         ) {
//           messages.push(message);
//         }
//       });

//     } else {

//     }

//     return messages;
//   } catch (error) {

//     throw error;
//   }
// }



export const getMobileFcm = async (userId: any) => {
  const userRef = ref(database, `users/${userId}`);
  const userSnapshot = await get(userRef);


let fcmObj = {fcm :  userSnapshot.val().notificationTokens && Object.keys( userSnapshot.val().notificationTokens)[0] , device : Object.values( userSnapshot.val().notificationTokens)[0]}
return fcmObj;
  
}

export const requestUserPermission = async (sessionIdentifier: any) => {
  try {   const permission = await Notification.requestPermission();
    
    const fcmToken = await getToken(messaging, {
      vapidKey:
        "BP_OWQ3M8st6raUPsGVYzCV-guhXzji_ZH0wTG8ipCBelJcaVAVQBDfNOKgBEreemQcEQ1cRXzb9he38JLf0x1U",
    }); // Replace with your actual VAPID key
  
    if (permission === "granted") {

   
      onMessage(messaging, (message) => {
        // alert(message);
        
   
      });
      const webSessionsRef = ref(database, "webSessions");

      // Search for the session with the specified sessionIdentifier
      const sessionsQuery = query(
        webSessionsRef,
        orderByChild("session_identifier"),
        equalTo(sessionIdentifier)
      );

      // Execute the query and get the snapshot
      get(sessionsQuery)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Get the sessionId of the found session
            const sessionIdToUpdate = Object.keys(snapshot.val())[0];
        
            // Update the fcmToken for the specified session
            const sessionRef = ref(
              database, 
              `webSessions/${sessionIdToUpdate}`
            );
            return update(sessionRef, { fcmToken: fcmToken });
          } else {
      
            return Promise.resolve();
          }
        })
        .then(() => {
        
        })
        .catch((error) => {
          // console.error("Error updating FcmToken:", error);
        });
    } else {
      
    }
  } catch (error) {
    // console.error("Error getting FCM token:", error);
  }
};

export const updateUserMessage = async (messageObj: any) => {
  try {
    const messageRef = ref(database, `messages/${messageObj.key}`);
    const updatedMessage = {
      content: messageObj.content,
    };
    await update(messageRef, updatedMessage);
  } catch (error) {
    throw error;
  }
};

export const deleteUserMessage = async (messageObj: any) => {
  try {
    const messageRef = ref(database, `messages/${messageObj.key}`);
    await remove(messageRef);
  } catch (error) {
    throw error;
  }
};

// export const logOutUsers = async () => {
//   try {
//     const messageRef = ref(
//       database,
//       `webSessions/identifier=${localStorage.getItem("sessionIdentifier")}`
//     );
//     await remove(messageRef);
//   } catch (error) {
//     throw error;
//   }
// };


export const updateGroupMessage = async (messageObj: any) => {
  try {
    

    const messageRef = ref(
      database,
      `groupsMessages/${messageObj?.groupId}/${messageObj?.key}`
    );
    const updatedMessage = {
      content: messageObj?.content,
    };
    await update(messageRef, updatedMessage);
  } catch (error) {
    throw error;
  }
};

export const deleteGroupMessage = async (messageObj: any) => {
  try {
    const messageRef = ref(
      database,
      `groupsMessages/${messageObj?.groupId}/${messageObj.key}`
    );
    await remove(messageRef);
  } catch (error) {
    throw error;
  }
};

export const uploadFile = async (file: any) => {
  try {
    if (!file) {
      throw new Error("No file provided for upload.");
    }

    const uniqueFileName = `${Date.now()}-${Math.random()
      .toString(36)
      .substring(2, 10)}`;
    let fileLocation = "";


    switch (file.type) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        fileLocation = "image";
        break;

      case "video/mp4":
      case "video/avi":
      case "video/mkv":
        fileLocation = "video";
        break;

      case "audio/mpeg":
      case "audio/wav":
      case "audio/ogg":
        fileLocation = "audio";
        break;

      case "application/pdf":
      case "application/zip":
        fileLocation = "file";
        break;

      default:
        fileLocation = "file";
        break;
    }

    const storageRef = sref(storage, `/${fileLocation}/${uniqueFileName}`);
   
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Use Promise to await the completion of the upload
    const fileUrl = await new Promise<any>((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
         
        },
        (err) => {
       
          reject(err);
        },
        async () => {
          // File uploaded successfully
          try {
            // const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
       
            // Additional actions or updates can be performed here

            // Resolve the promise with the file URL
            // resolve(`/${fileLocation}/${uniqueFileName}`);
            resolve({
              fileLocation: fileLocation,
              uniqueFileName: uniqueFileName,
            });
          } catch (err) {
            // console.error("Error getting download URL:", err);
            reject(err);
          }
        }
      );
    });

    return fileUrl;
  } catch (error) {
    // console.error("Error during file upload:", error);
    throw error;
  }
};
function async(arg0: string) {
  throw new Error("Function not implemented.");
}
