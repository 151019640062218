/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../styles/web.css";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import { useWebSocketContext } from "../../_contexts/WebSocketContext"; // Import WebSocket context
import { getAuthenticated } from "../../redux/actions/user";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";

export default function WebQrLayout() {
    const sessionIdentifier = localStorage.getItem('sessionIdentifier');
    const [showQR, setShowQR] = useState(false);
    const [webSessionQR, setWebSessionQR] = useState("");
    const { webSocket, setSocketConnection, disconnectSocket } = useWebSocketContext();
    const dispatch = useAppDispatch();
    const { isAuthenticated, loading } = useAppSelector(state => state.user)


    useEffect(() => {
        let interval = setTimeout(() => {
            if (!isAuthenticated)
                setSocketConnection("");
        }, 2000)
        return () => clearTimeout(interval)
    }, []);

    const events = {
        initUniqueSessionIdentifier: "init-unique-session-identifier",
        updateUniqueSessionIdentifier: "update-unique-session-identifier",
        authUser: "auth-user",
    };

    function initWebSocket() {
        let updateTimer: any = null;

        if (webSocket) {
            webSocket.onmessage = function (event: any) {
                const data = JSON.parse(event.data);
                const step = data.data && data.data.step;
                if (step === 0) {
                    QRCode.toString(
                        data.data.token,
                        {
                            errorCorrectionLevel: 'H',
                            width: 300,
                            type: 'svg',
                            version: 17,
                        },
                        function (err: any, qr: any) {
                            if (err) throw err;
                            setWebSessionQR(qr);
                            setShowQR(true);
                        },
                    );

                    localStorage.setItem("sessionIdentifier", data.data.identifier);
                    const updateInterval = 2 * 60 * 1000; // 2 minutes
                    updateTimer = setInterval(() => {
                        let identifier = localStorage.getItem("sessionIdentifier");
                        if (identifier === null) {
                            webSocket.send(JSON.stringify({ mock: "server", event: events.initUniqueSessionIdentifier, type: 0 }));
                        } else {
                            webSocket.send(
                                JSON.stringify({
                                    mock: "client",
                                    event: events.updateUniqueSessionIdentifier,
                                    identifier,
                                    type: 1,
                                }),
                            );
                        }
                    }, updateInterval);
                } else if (step === 1) {
                    QRCode.toString(
                        data.data.token,
                        {
                            errorCorrectionLevel: 'H',
                            width: 300,
                            type: 'svg',
                            version: 17,
                        },
                        function (err: any, qr: any) {
                            if (err) throw err;
                           
                            setWebSessionQR(qr);
                            // setShowQR(true);
                        },
                    );
                } else if (step === 2) {
                    const { token } = data.data;
                    localStorage.setItem("nz-auth", token)
                    const sessionIdentifier: any = localStorage.getItem("sessionIdentifier");
                    webSocket.close();
                    dispatch(getAuthenticated(sessionIdentifier))
                }
            };

            webSocket.onopen = function () {
                webSocket.send(JSON.stringify({ mock: "server", event: events.initUniqueSessionIdentifier, type: 0 }));
            };

            webSocket.onclose = function () {
                clearInterval(updateTimer);
            };
        }
    }


    useEffect(() => {
        if (webSocket) {
            initWebSocket()

            return (() => disconnectSocket());
        }
    }, [webSocket]);


    return (
        <>
            <section className="web_container_wrapper">
                <div className="web_container_section_bg">
                    <img className="app-logo" src="images/app_logo.png" alt="" />
                </div>
                <div className="web_container_inner-wrapper">
                    <h2>Use NZ Chat on your computer</h2>
                    <div className="web_container_inner-container">
                        <div className="web_container_inner_left">
                            <div className="web_container_inner_content">
                                <ol className="">
                                    <li>Open NZ Chat on your phone</li>
                                    <li>
                                        Go to settings by tapping on your profile photo,{" "}
                                        <strong>Menu</strong>, or <strong>Settings</strong>
                                    </li>
                                    <li>
                                        Tap <strong>Linked devices</strong> and then
                                        <strong> Link a device</strong>
                                    </li>
                                    <li>
                                        <span>
                                            Point your phone to this screen to capture the QR code
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="web_container_inner_right">
                            <div className="web_container_inner_content">
                                {showQR ? (
                                    <div className="qr-img" dangerouslySetInnerHTML={{ __html: webSessionQR }} />
                                ) : (
                                    <img
                                        className="loader-img"
                                        src="images/loader.gif"
                                        alt="Loader"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
