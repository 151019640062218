export const toastConfig = [
  {
    className: "",
    duration: 5000,
    style: {
      background: "#363636",
      color: "#fff",
    },
    success: {
      duration: 3000,
      theme: {
        primary: "green",
        secondary: "black",
      },
    },
  },
];
