import React, { ReactNode } from "react";
import { Toaster, DefaultToastOptions } from "react-hot-toast";
import VideoCallProvider from "../../_contexts/VideoCallContext";
import MeetingProvider from "../../_contexts/MeetingContext";
import {ChatProvider} from "../../_contexts/ChatContext";
import { toastConfig } from "../../_utils";
import AudioCallProvider from "../../_contexts/AudioCallContext";

interface MessengerLayoutProps {
  children: ReactNode;
}

const MessengerLayout: React.FC<MessengerLayoutProps> = ({ children }) => (
  <>
    <MeetingProvider>
      <AudioCallProvider> 
      <VideoCallProvider>
        {/* <ChatProvider> */}
          <>{children}</>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={10}
            containerClassName=""
            containerStyle={{}}
            toastOptions={toastConfig as DefaultToastOptions}
          />
        {/* </ChatProvider> */}
      </VideoCallProvider>
      </AudioCallProvider>
    </MeetingProvider>
  </>
);

export default MessengerLayout;
