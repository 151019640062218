import React, { createContext, useContext, useState, useEffect } from "react";
import {
  deleteGroupMessage,
  deleteUserMessage,
  getAllGroupMessages,
  getUserMessages,
  updateGroupMessage,
  updateUserMessage,
} from "../_utils/firebase";
import { encryption } from "../_utils/cryptography";
import { useAppSelector } from "../redux/Hooks";
import { useDispatch } from "react-redux";
import {
  setAllMessagesInRedux,
  setAllGroupMessagesInRedux,
} from "../redux/actions/user";

const ChatContext = createContext(undefined);

export const ChatProvider = ({ children }) => {
  const [allMessages, setAllMessages] = useState({});
  // new code
  const [newMessages, setNewMessages] = useState([]);

  const [allGroupMessages, setAllGroupMessages] = useState({});
  const [editMessage, setEditMessage] = useState<string>("");
  const [isChatOpen, setIsChatOpen] = useState(null)
  const [isEditMessageAction, setIsEditMessageAction] =
    useState<boolean>(false);
  const [editType, setEditType] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAllmsg, setshowAllmsg] = useState(0);
  const [showMsg, setshowmsg] = useState({})
  const { uid: currentChaterId, userType } = useAppSelector(
    (state) => state.currentChat
  );

  useEffect(() => {
    // alert(showAllmsg)
    // showAllmsg != 0 && setshowmsg(true)
    localStorage.setItem('show', 'true')
  }, [showAllmsg])
  const userChat = localStorage.getItem("userMessages");
  const groupChats = localStorage.getItem("groupMessages");

  const init = (msg: any) => {
    setAllMessages(() => {
      return { ...msg }
    })
    setshowAllmsg(Math.random())
  };

  const initChat = async (uid: any, dispatch: any) => {
   
    let messages = await getUserMessages(uid, init);

    // setAllMessages({ ...messages });
    let groupMessages = await getAllGroupMessages(uid);
    setAllGroupMessages({ ...groupMessages });

  };

  const generateNotification = () => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const notification = new Notification("nz-chat", {
        body: "New Message Recived!",
      });
      notification.onclick = () => {
        const newTab = window.open("https://nz-chat.com/chat", "_blank");
        newTab.focus();
      };
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification("nz-chat", {
            body: "New Message Recived!",
          });
          notification.onclick = () => {
            const newTab = window.open("https://nz-chat.com/chat", "_blank");
            newTab.focus();
            // window.location.href = 'https://nz-chat.com/chat';
          };
        }
      });
    }
  };

  // const addMessage = (userId: any, message: any) => {
  //   setAllMessages((prevMessages) => ({
  //     ...prevMessages,
  //     [userId]: [message], // Replace the existing messages with the new message
  //   }));
  // };


  const addMessage = (userId: any, message: any) => {
    if (!allMessages.hasOwnProperty(userId)) {
      setAllMessages((prevMessages) => ({
        ...prevMessages,
        [userId]: [],
      }));
    }
    // Add the message to the user's array
    if (
      !allMessages[userId]?.some(
        (existingMessage) => existingMessage.key === message.key
      )
    ) {
      if (allMessages && message.hasOwnProperty("messageId")) {
        generateNotification();
      }
      setAllMessages((prevMessages) => ({
        ...prevMessages,
        [userId]: [...prevMessages[userId], message],
      }));
    }
  };

  const addGroupMessage = (groudId: any, message: any) => {
    if (!allGroupMessages.hasOwnProperty(groudId)) {
      setAllGroupMessages((prevMessages) => ({
        ...prevMessages,
        [groudId]: [],
      }));
    }
    if (
      !allGroupMessages[groudId]?.some(
        (existingMessage) => existingMessage.key === message.key
      )
    ) {
      if (allMessages && message.hasOwnProperty("messageId")) {
        generateNotification();
      }
      // Add the message to the user's array
      setAllGroupMessages((prevMessages) => ({
        ...prevMessages,
        [groudId]: [...prevMessages[groudId], message],
      }));
    }
  };

  const handleUpdateMessage = async (messageContent, text, type) => {
    if (type === "individualMessage") {
      const index = allMessages[messageContent?.messageObject.toId].findIndex(
        (object) => {
          return object.key === messageContent?.messageObject?.key;
        }
      );
      const ecryptedMsg = await encryption(text);
      allMessages[messageContent.messageObject.toId][index].content =
        ecryptedMsg;
      setIsEditMessageAction(false);
      updateUserMessage(allMessages[messageContent.messageObject.toId][index]);
    }

    if (type === "updateGroupMessage") {
      const index = allGroupMessages[currentChaterId].findIndex((object) => {
        return object.key === messageContent?.messageObject?.key;
      });
      allGroupMessages[currentChaterId][index].groupId = currentChaterId;

      const ecryptedMsg = await encryption(text);

      allGroupMessages[currentChaterId][index].content = ecryptedMsg;
      setIsEditMessageAction(false);
      updateGroupMessage(allGroupMessages[currentChaterId][index]);
    }
  };


  const handleDeleteMessage = (e, deleteType) => {
    if (deleteType === "individual") {
      const updatedMessages = allMessages[e?.messageObject.toId].filter(
        (object) => {
          return object.key !== e?.messageObject?.key;
        }
      );

      setAllMessages((prevAllMessages) => {
        // Make sure to create a new copy of the state to trigger a re-render
        const updatedState = { ...prevAllMessages };
        updatedState[e?.messageObject.toId] = updatedMessages;
        return updatedState;
      });
      deleteUserMessage(e?.messageObject);
    }
    if (deleteType === "group") {
   

      const updatedMessages = allGroupMessages[currentChaterId].filter(
        (object) => {
          return object.key !== e?.messageObject?.key;
        }
      );

      setAllGroupMessages((prevAllMessages) => {
        // Make sure to create a new copy of the state to trigger a re-render
        const updatedState = { ...prevAllMessages };
        updatedState[currentChaterId] = updatedMessages;
        return updatedState;
      });
      e.messageObject.groupId = currentChaterId;
      deleteGroupMessage(e?.messageObject);
    }
  };


  const handleEditMessage = (e, editFlag) => {
    setEditMessage(e);
    setIsEditMessageAction(editFlag);
  };

  const contextValue = {
    allMessages,
    allGroupMessages,
    addMessage,
    addGroupMessage,
    initChat,
    editMessage,
    isEditMessageAction,
    handleEditMessage,
    handleUpdateMessage,
    handleDeleteMessage,
    setEditType,
    editType,
    setShowEmojiPicker,
    showEmojiPicker,
    isChatOpen,
    setIsChatOpen,
    showAllmsg, setshowAllmsg, setshowmsg
  };

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }

  return context;
};
