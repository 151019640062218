/* eslint-disable @typescript-eslint/no-unused-vars */
import "./App.css";
import routes from "./Router/routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import Loader from "react-js-loader";
import PrivateRoute from "./Router/PrivateRoute";
import Chat from "./pages/Chat";
import { getAuthenticated } from "./redux/actions/user";
import Web from "./pages/Web";
import MainLayout from "./components/Layouts/MainLayout";
import MessengerLayout from "./components/Layouts/MessengerLayout";
import Meeting from "./pages/Meeting";
import VideoCall from "./pages/VideoCall";
import "react-loading-skeleton/dist/skeleton.css";
import { useAppDispatch, useAppSelector } from "./redux/Hooks";
import AudioCall from "./pages/AudioCall";
import { requestUserPermission } from "./_utils/firebase";

function App() {
  const sessionIdentifier = localStorage.getItem("sessionIdentifier");
  const queryString = window.location.search;

  // Create a URLSearchParams object from the search string
  const searchParams = new URLSearchParams(queryString);
  const { isAuthenticated, loading: authLoading } = useAppSelector(
    (state: any) => state.user
  );
  const meetingCode = searchParams.get("meeting");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const myQueryParam = searchParams.get("meeting");
  useEffect(() => {
    setLoading(true);
    const authenticateUser = async () => {
      if (sessionIdentifier) {
        dispatch(getAuthenticated(sessionIdentifier));
      }
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    };
    // searchParams.get("__firebase_request_key");
    

    authenticateUser();
  }, [dispatch, sessionIdentifier]);
  useEffect(() => {
    setInterval(() => {



      localStorage.getItem('requestUserPermission') && requestUserPermission(localStorage.getItem('requestUserPermission'))
    }, 200);
      } , []) 
  if (loading || authLoading) {
    return (
      <div className="loader-container">
        <Loader
          type="bubble-scale"
          bgColor={`#2cb45f`}
          color={"#28282882"}
          size={50}
        />
      </div>
    );
  }



  return (
    <div className="App">
      <Router>
        <Routes>
          {routes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={
                <Suspense
                  fallback={
                    <>
                      {" "}
                      <Loader
                        type="bubble-scale"
                        bgColor={`#2cb45f`}
                        color={"#28282882"}
                        size={50}
                      />
                    </>
                  }
                >
                  {item.element}
                </Suspense>
              }
            />
          ))}

          <Route
            path={meetingCode ? `/chat?meeting=${meetingCode}` : "/chat"}
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                redirect="/nz-chat-web"
              >
                <MessengerLayout>
                  <Chat />
                </MessengerLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/nz-chat-web"
            element={
              <PrivateRoute isAuthenticated={!isAuthenticated} redirect="/chat">
                <MainLayout>
                  <Web />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/meeting"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                redirect={`/nz-chat-web?meting=${meetingCode}`}
              >
                <MessengerLayout>
                  <Meeting />
                </MessengerLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/video-call"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                redirect="/nz-chat-web"
              >
                <MessengerLayout>
                  <VideoCall />
                </MessengerLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/audio-call"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                redirect="/nz-chat-web"
              >
                <MessengerLayout>
                  <AudioCall />
                </MessengerLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
