/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { decription } from "../../../_utils/cryptography";
import { getFileFromFireStorage } from "../../../_utils/firebase";
import {
  checkUrlType,
  getTextFromHTMLString,
  timestampToTime,
} from "../../../_utils";
import MessageAction from "./MessageAction";
import { useChatContext } from "../../../_contexts/ChatContext";
import { ShowMediaModal } from "./showMediaModal";
const GroupMessage: React.FC<any> = ({ message, userId }) => {
  const [finalMessage, setFinalMessage] = useState<string>("");
  const [mediaValue, setMediaValue] = useState({
    showModal: false,
    mediaType: "",
    url: "",
  });
  const [file, setFile] = useState<string>("");

  const { handleEditMessage, setEditType, handleDeleteMessage } =
    useChatContext();

  const handelSelectedMessage = (content) => {
    switch (content.actionsType) {
      case "edit":
        handleEditMessage(content, true);
        setEditType("updateGroupMessage");
        return;
      case "copy":
        navigator.clipboard.writeText(getTextFromHTMLString(finalMessage));
        return;
      case "delete":
        handleDeleteMessage(content, "group");
        return;
    }
  };

  useEffect(() => {
    decription(message.content).then((content) => {
      if (message && message.type === 33) {
        if (message.fileSize) {
          setFile(content);
        } else {
          getFileFromFireStorage(content)
            .then((filePath) => {
              setFile(filePath);
            })
            .catch((error) => {
              setFile("");
            });
        }
      } else if (
        message &&
        (message.type === 13 ||
          message.type === 5 ||
          message.type === 2 ||
          message.type === 11)
      ) {
        getFileFromFireStorage(content)
          .then((filePath) => {
            setFile(filePath);
          })
          .catch((error) => {
            setFile("");
          });
      } else if (message && message.type === 1) {
        setFinalMessage(content);
      } else {
        setFinalMessage(content);
      }
    });
  }, [message.content, message.type]);

  const handleCloseMediaModal = () => {
    setMediaValue((prev) => {
      return { ...prev, showModal: false, mediaType: "", url: "" };
    });
  };

  return (
    <>
      <ShowMediaModal
        mediaVal={mediaValue}
        closeModal={handleCloseMediaModal}
      />
      {message.fromId !== userId ? (
        <div className="message-box friend-message">
          <p>
            {(message.type === 33 || message.type === 2) && (
              <>
                <img
                  src={file}
                  alt="image not found"
                  onClick={() => {
                    setMediaValue((prev) => {
                      return {
                        ...prev,
                        showModal: true,
                        mediaType: "image",
                        url: file,
                      };
                    });
                  }}
                />
              </>
            )}

            {message.type === 13 && (
              <a href={file} target="_blank" rel="noopener noreferrer">
                {message.metadata}
              </a>
            )}
            {message.type === 5 && <video src={file} controls width="100%" />}
            {message.type === 11 && <audio src={file} controls />}
            {(message.type !== 33 ||
              message.type !== 2 ||
              message.type !== 13 ||
              message.type !== 5 ||
              message.type !== 11) && (
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: checkUrlType(finalMessage),
                    }}
                  />
                </p>
                {message.type === 1 && (
                  <MessageAction
                    uid={userId}
                    messageObject={message}
                    message={finalMessage}
                    onMessageModify={handelSelectedMessage}
                  />
                )}
              </>
            )}
            <br />
            <small className="chat-time">
              {timestampToTime(message.timestamp)}
            </small>
          </p>
        </div>
      ) : (
        <div className="message-box my-message">
          <p>
            {(message.type === 33 || message.type === 2) && (
              <img
                src={file}
                alt="image not found"
                onClick={() => {
                  setMediaValue((prev) => {
                    return {
                      ...prev,
                      showModal: true,
                      mediaType: "image",
                      url: file,
                    };
                  });
                }}
              />
            )}

            {message.type === 13 && (
              <a href={file} target="_blank" rel="noopener noreferrer">
                {message.metadata}
              </a>
            )}
            {message.type === 5 && <video src={file} controls width="100%" />}
            {message.type === 11 && <audio src={file} controls />}
            {(message.type !== 33 ||
              message.type !== 2 ||
              message.type !== 13 ||
              message.type !== 5 ||
              message.type !== 11) && (
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: checkUrlType(finalMessage),
                    }}
                  />
                </p>
                <MessageAction
                  uid={userId}
                  messageObject={message}
                  message={finalMessage}
                  onMessageModify={handelSelectedMessage}
                />
              </>
            )}
            <br />

            <small className="chat-time">
              {timestampToTime(message.timestamp)}
            </small>
          </p>
        </div>
      )}
    </>
  );
};

export default GroupMessage;
