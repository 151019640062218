/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import RemoteVideoMute from './Controls/Remote/RemoteVideoMute'
import RemoteAudioMute from './Controls/Remote/RemoteAudioMute'
import PropsContext, { VideoPlaceholderProps } from './PropsContext'
import SwapUser from './Controls/SwapUser'
import { useAppSelector } from '../redux/Hooks'
import { stat } from 'fs'
/**
 * React component that is renderd when the video track is muted.
 */
const VideoPlaceholder = (props: VideoPlaceholderProps) => {
  const { styleProps, rtcProps } = useContext(PropsContext)
  const { maxViewStyles, maxViewOverlayContainer } = styleProps || {}
  const { user } = props
  const { CustomVideoPlaceholder } = rtcProps
  const  userss = useAppSelector(state => state.user)
  const calluser = useAppSelector((state) => state.currentCall);
      
  return !CustomVideoPlaceholder ? (<div
      key={user.uid}
      style={{
        ...style.max,
        ...maxViewStyles
      }}
    ><div style={style.imgContainer}>
        <img
          style={style.img}
          // src={userss.uid == calluser.callerId ? userss?.photo : calluser?.photo}
          src='https://firebasestorage.googleapis.com/v0/b/nzchat.appspot.com/o/default_user_img.png?alt=media&token=3b78244f-fa1c-42fe-b289-0e1c9923b043'
        />
      </div>
      {props.isShown && (
        <div
          style={{
            ...style.btnContainer,
            ...maxViewOverlayContainer
          }}
        >
          {props.showButtons && (
            <React.Fragment>
              {!rtcProps.disableRtm && <RemoteVideoMute UIKitUser={user} />}
              {!rtcProps.disableRtm && <RemoteAudioMute UIKitUser={user} />}
              {props.showSwap && <SwapUser UIKitUser={user} />}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  ) : (
    CustomVideoPlaceholder && CustomVideoPlaceholder({ ...props }, null)
  )
}

const style = {
  max: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#007bff33',
    flexDirection: 'row',
    position: 'relative'
  } as React.CSSProperties,
  imgContainer: {
    flex: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    borderRadius:'100%',
    boxShadow:'10px',
    width: 100,
    height: 100,
    position: 'absolute',
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    display: 'flex'
  } as React.CSSProperties,
  btnContainer: {
    position: 'absolute',
    margin: 5,
    flexDirection: 'column',
    display: 'flex'
  } as React.CSSProperties
}

export default VideoPlaceholder
