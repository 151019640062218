import React from "react";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import DefaultScreen from "./DefaultScreen";
import { useAppSelector } from "../../../redux/Hooks";
import { useChatContext } from "../../../_contexts/ChatContext";

const ChatScreen = () => {
  const { allMessages, allGroupMessages, addMessage } = useChatContext();
  const { uid, userType } = useAppSelector((state) => state.currentChat);
  return (
    <>
      <div className="right-container">
        {uid ? (
          <>
            <ChatHeader />
            <ChatBody
              allMessages={
                userType === "user" ? allMessages[uid] : allGroupMessages[uid]
              }
            />
          </>
        ) : (
          <DefaultScreen />
        )}
      </div>
    </>
  );
};

export default ChatScreen;
