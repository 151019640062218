import { createReducer, PayloadAction } from "@reduxjs/toolkit";
const userInitialState: any = {
  isAuthenticated: false,
  loading: false,
  updateLoading: false,
};
export const userReducer = createReducer(userInitialState, {
  authRequest: (state) => {
    state.loading = true;
  },
  authSuccess: (state, action: PayloadAction<{ uid: string; data: any }>) => {
    state.loading = false;
    state.uid = action.payload.uid;
    state.name = action.payload.data.name;
    state.phone = action.payload.data.phone;
    state.notificationTokens = action.payload.data.notificationTokens;
    state.photo = action.payload.data.photo;
    state.status = action.payload.data.status;
    state.thumbImg = action.payload.data.thumbImg;
    state.isAuthenticated = true;
    state.message = "Authentication Successful";
  },
  authFailure: (state, action: PayloadAction<string>) => {
    state.loading = false;
    state.isAuthenticated = false;
    state.error = action.payload;
  },
  logoutRequest: (state) => {
    state.loading = true;
  },
  logoutSuccess: (state) => {
    state.loading = false;
    state.isAuthenticated = false;
    state.uid = undefined;
    state.name = undefined;
    state.phone = undefined;
    state.photo = undefined;
    state.status = undefined;
    state.thumbImg = undefined;
    state.message = "Logout Successfully";
  },
  logoutFailure: (state, action: PayloadAction<string>) => {
    state.loading = false;
    state.error = action.payload;
  },
  updateRequest: (state) => {
    state.updateLoading = true;
  },
  updateSuccess: (state, action) => {
    state.updateLoading = false;
    state.name = action.payload.name;
    state.photo = action.payload.photo;
    state.status = action.payload.status;
  },
  updateFailure: (state) => {
    state.updateLoading = false;
  },
});


export const chatReducer = createReducer<any>({}, {
  currentChat: (state, action: PayloadAction<any>) => {
    state.uid = action.payload.userId;
    state.name = action.payload.name;
    state.photo = action.payload.photo;
    state.userType = action.payload.userType;
    if (action.payload.userType === 'group') {
      state.groupUsers = action.payload.groupUsers;
    } else {
      delete state.groupUsers;
    }
  },
});

//CHAT

const initialCallState = {};
export const callReducer = createReducer<any>(initialCallState, {
  currentCall: (state, action: PayloadAction<any>) => {
    return { ...state, ...action.payload };
  },
});

//MESSAGES

let initialMessages= {}
export const allMessagesReducer = createReducer<any>(initialMessages, {
  reduxAllMessages: (state, action: PayloadAction<any>) => {
    return { ...state, ...action.payload };
  },
});


let initialGroupMessages = {};
export const allGroupMessagesReducer = createReducer<any>(initialGroupMessages, {
  reduxAllGroupMessages: (state, action: PayloadAction<any>) => {
    return { ...state, ...action.payload };
  },
});

//GROUPCALL FUNCTION

const groupCallState = [];
export const groupCallReducesr = createReducer<any>(groupCallState, { 
  currentGroupCall: (state, action: PayloadAction<any>) => {
    if(action.payload === null){
      return {};      
    }
    return { ...state, ...action.payload };
  },
})

//REMP CHAT DATA
const initialChatState = {};
export const tempChatDataReducer = createReducer<any>(initialChatState, {
  tempChatData: (state, action: PayloadAction<any>) => {
    return {data:action.payload };
  },
});