/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useAudioCallContext } from "../_contexts/AudioCallContext";
import AgoraUIKit from "../agora-ui-kit";
import CallWaitingScrren from "../components/meeting/common/CallWaitingScrren";
import AudioCallWitingScreen from "../components/meeting/common/AudioCallWaitingScreen";
import ConnectAudioCall from "./ConnectAudioCall";

const AudioCall = () => {
  const {
    audioCallStep,
    sharedcallOnChannelName,
    callOnChannelName,
    joined,
    rtcProps,
    rtmProps,
    handelLeaveCall,
    handelAudioCallStep,
    handelEndIncomingVoiceCallToFriebase,
    handelEndSelfVoiceCallToFirebase,
  } = useAudioCallContext();
  // useEffect(() => {
  //   setTimeout(() => {
  //     handelAudioCallStep(2);
  //   }, 10000);
  // }, []);
  return (
    <>
      {audioCallStep === 1 && !sharedcallOnChannelName && (
        <>
          <AudioCallWitingScreen
            onLeaveCall={() => {
              handelEndSelfVoiceCallToFirebase();
            }}
          />
        </>
      )}
      {audioCallStep === 2 && (
        <>
          <div style={styles.container}>
            {/* need to remove this kit will use core functions  */}
            {/* <AgoraUIKit
                        rtcProps={rtcProps}
                        rtmProps={rtmProps}
                        callbacks={{
                            EndCall: () => handelLeaveCall(),
                        }}
                    /> */}
            <ConnectAudioCall />
          </div>
        </>
      )}
    </>
  );
};
const styles = {
  container: { width: "100vw", height: "100vh", display: "flex", flex: 1 },
};
export default AudioCall;
