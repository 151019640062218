import React, { useState, CSSProperties } from 'react'
import ProfileInputBox from './ProfileInputBox'
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { updateProfilePicture } from '../../redux/actions/user';
import { ClipLoader } from 'react-spinners';

const Profile = ({ setShowProfile }) => {
    const { uid, photo } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch()
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "green",
    };

    async function handleChange(event: any) {
        // setLoading(false)
        try {
            setLoading(true)

            setTimeout(() => {
                setLoading(false)
            }, 3000);
            dispatch(updateProfilePicture(uid, event.target.files[0]));

        }
        catch (error) {
            throw error;
        }

    }
    return (
        <div className='profile-section'>
            <div className='profile-header' onClick={() => setShowProfile(false)}>
                <i className="ri-arrow-left-line"></i>
            </div>
            <div className='profile-content'>
                <div className="profile-upload-section">
                    <img className="app-logo" src={photo} alt="" />
                    <ClipLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className='profile-loader'
                    />
                    <div className='profile-upload'>
                        <input type="file" accept="image/*" id='profile-change' onChange={handleChange} />

                        <label htmlFor="profile-change">Change profile</label>
                    </div>
                </div>
                <ProfileInputBox title="Name" dbKey="name" />
                <ProfileInputBox title="About me" dbKey="status" />
            </div>
        </div>
    )
}

export default Profile