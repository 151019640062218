/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, CSSProperties } from "react";
import { decription } from "../../../_utils/cryptography";
import { getFileFromFireStorage } from "../../../_utils/firebase";
import {
  timestampToTime,
  getTextFromHTMLString,
  checkUrlType,
} from "../../../_utils";
import MessageAction from "./MessageAction";
import { useChatContext } from "../../../_contexts/ChatContext";
import { ShowMediaModal } from "./showMediaModal";

// type1 : text;
// type2 : image;
// type13 : file;
// type33 : sticker;
// type5 : video;
// type11 : voice;

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const ChatMessage: React.FC<any> = ({ message, currentChaterId, userId }) => {
  const [finalMessage, setFinalMessage] = useState<string>("");
  const [messagelen, setmessagelen] = useState(200);
  const [file, setFile] = useState<string>("");
  const [mediaValue, setMediaValue] = useState({
    showModal: false,
    mediaType: "",
    url: "",
  });
  const {
    handleEditMessage,
    handleDeleteMessage,
    setEditType,
    setdeleteType,
    allMessages,
  } = useChatContext();
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  const handelSelectedMessage = (content) => {
  
    switch (content.actionsType) {
      case "edit":
        handleEditMessage(content, true);
        setEditType("individualMessage");
        return;
      case "copy":
        navigator.clipboard.writeText(getTextFromHTMLString(finalMessage));
        return;
      case "delete":
        handleDeleteMessage(content, "individual");
        return;
    }
  };

  const handleCloseMediaModal = () => {
  
    setMediaValue((prev) => {
      return { ...prev, showModal: false, mediaType: "", url: "" };
    });
  };

  //   useEffect(() => {
  //   if (writerInputRef && isEditAction) {
  //     const editor = writerInputRef.current.getEditor();
  //     let deltaMessage = editor.clipboard.convert(
  //       messageForEdit?.message.message
  //     );
  //     editor.setContents(deltaMessage, "silent");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isEditAction]);
  useEffect(() => {
    decription(message.content).then((content) => {
      if (message && message.type === 33) {
        if (message.fileSize) {
          setFile(content);
        } else {
          getFileFromFireStorage(content)
            .then((filePath) => {
              setFile(filePath);
            })
            .catch((error) => {
              setFile("");
            });
        }
      } else if (
        message &&
        (message.type === 13 ||
          message.type === 5 ||
          message.type === 2 ||
          message.type === 11)
      ) {
        getFileFromFireStorage(content)
          .then((filePath) => {
            setFile(filePath);
            setLoading(false);
             setFinalMessage("");
          })
          .catch((error) => {
            setFile("");
          });
      } else if (message && message.type === 1) {
        setFinalMessage(content);
      } else {
        setFinalMessage(content);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.content, message.type]);
 

  return (
    <>
      <ShowMediaModal
        mediaVal={mediaValue}
        closeModal={handleCloseMediaModal}
      />
      {/* messages that we recive form others */}
      {message &&
        message?.fromId === currentChaterId &&
        message.toId === userId && (
          <div className="message-box friend-message">
            {(message.type === 33 || message.type === 2) && (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                src={file}
                alt="image not found"
                onClick={() => {
                  setMediaValue((prev) => {
                    return {
                      ...prev,
                      showModal: true,
                      mediaType: "image",
                      url: file,
                    };
                  });
                }}
              />
            )}
            {message.type === 13 && (
              <a href={file} target="_blank" rel="noopener noreferrer">
                {message.metadata}
              </a>
            )}
            {message.type === 5 && <video src={file} controls width="100%" />}
            {message.type === 11 && <audio src={file} controls />}
            {(message.type !== 33 ||
              message.type !== 2 ||
              message.type !== 13 ||
              message.type !== 5 ||
              message.type !== 11) && (
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {" "}
                  {finalMessage && finalMessage.length <= messagelen ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: checkUrlType(finalMessage),
                      }}
                    />
                  ) : (
                    <div>
                     {finalMessage ? (
  <div
    dangerouslySetInnerHTML={{
      __html: finalMessage.slice(0, 250),
    }}
  />
) : (
  <div>Invalid Message</div>
)}
                      <p
                        onClick={() => {
                          setmessagelen(2000000);
                        }}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Read more
                      </p>
                    </div>
                  )}
                </p>
                {message.type === 1 && (
                  <MessageAction
                    uid={userId}
                    messageObject={message}
                    message={finalMessage}
                    onMessageModify={handelSelectedMessage}
                  />
                )}
              </>
            )}
            <small className="chat-time">
              {timestampToTime(message.timestamp)}
            </small>
          </div>
        )}
      {/* messages that we send to other */}
      {message &&
        message?.fromId === userId &&
        message?.toId === currentChaterId && (
          <div className="message-box my-message">
            {(message.type === 33 || message.type === 2) && file && (
              <img
                src={file}
                alt="image not found here"
                onClick={() => {
                  setMediaValue((prev) => {
                    return {
                      ...prev,
                      showModal: true,
                      mediaType: "image",
                      url: file,
                    };
                  });
                }}
              />
            )}
            {message.type === 13 && (
              <a href={file} target="_blank" rel="noopener noreferrer">
                {message.metadata}
              </a>
            )}
            {message.type === 5 && <video src={file} controls width="100%" />}
            {message.type === 11 && <audio src={file} controls />}
            {(message.type !== 33 ||
              message.type !== 2 ||
              message.type !== 13 ||
              message.type !== 5 ||
              message.type !== 11) && (
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {" "}
                  {finalMessage?.length <= messagelen ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: checkUrlType(finalMessage),
                      }}
                    />
                  ) : (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: finalMessage?.slice(0, 250),
                        }}
                      />
                      <p
                        onClick={() => {
                          setmessagelen(2000000);
                        }}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Read more
                      </p>
                    </div>
                  )}
                </p>
                <MessageAction
                  uid={userId}
                  messageObject={message}
                  message={finalMessage}                  
                  onMessageModify={handelSelectedMessage}
                />
              </>
            )}
            <small className="chat-time">
              {timestampToTime(message.timestamp)}
            </small>
          </div>
        )}
    </>
  );
};
                         
export default ChatMessage;
