import { useLocation } from "react-router-dom";
import { useRef, useEffect, useCallback } from "react";
import { decodeUrlQuery } from "../_utils";

interface FormRef {
  current: {
    setValue: (query: { [key: string]: string }) => void;
  };
}

export const useUrlQuery = (formRef: FormRef) => {
  const { search } = useLocation() || {};
  useEffect(() => {
    const query = decodeUrlQuery(search);
    if (query.appId || query.channel) {
      formRef.current.setValue(query);
    }
  }, [formRef, search]);
};

export const useEffectOnce = (effect: React.EffectCallback) => {
  useEffect(effect, []);
};

export const useUnMount = (cb: () => void) => {
  const fnRef = useRef(cb);
  fnRef.current = cb;
  useEffectOnce(() => () => fnRef.current());
};

export const useAnimationFrame = (
  isRunning: boolean = true,
  callback: () => void = () => {}
) => {
  const reqIdRef = useRef<number | null>(null);

  const loop = useCallback(() => {
    if (isRunning) {
      reqIdRef.current = requestAnimationFrame(loop);
      callback();
    }
  }, [callback, isRunning]);

  useEffect(() => {
    reqIdRef.current = requestAnimationFrame(loop);
    return () => {
      if (reqIdRef.current !== null) {
        cancelAnimationFrame(reqIdRef.current);
      }
    };
  }, [loop]);
};
