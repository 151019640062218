import { Dropdown } from "react-bootstrap";

const MessageAction = ({ onMessageModify, messageObject, message, uid }) => {
  const handelMessageActions = (type) => {
    onMessageModify({ actionsType: type, messageObject, message });
  };

  
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="message-more-items-dropdown">
          {uid === messageObject.fromId && messageObject.type === 1 && (
            <Dropdown.Item
              onClick={() => {
                handelMessageActions("edit");
              }}
            >
              <i className="fa fa-edit"></i> Edit
            </Dropdown.Item>
          )}
          {messageObject.type === 1 && (
            <Dropdown.Item
              onClick={() => {
                handelMessageActions("copy");
              }}
            >
              <i className="fa fa-copy"></i> Copy
            </Dropdown.Item>
          )}

          {uid === messageObject.fromId && (
            <Dropdown.Item
              onClick={() => {
                handelMessageActions("delete");
              }}
            >
              <i className="fa fa-trash"></i> Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default MessageAction;
