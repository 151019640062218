/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVideoCallContext } from "../../../_contexts/VideoCallContext";
import { useAppSelector } from "../../../redux/Hooks";
import { database } from "../../../_utils/firebase";
import { off, onValue, ref } from "firebase/database";
import { CALL_STATUS, CALL_TYPE } from "../../../_utils";
import { useAudioCallContext } from "../../../_contexts/AudioCallContext";
interface CallScreenProps {
  onLeaveCall: () => void;
}
const AudioCallWitingScreen: React.FC<CallScreenProps> = ({ onLeaveCall }) => {
  let [queryString, setQueryString] = useSearchParams();
  const callTypeInURL = queryString.get("type");

  const {
    handelEndSelfCallToFirebase,
    handelCallUpdateListner,
    handelVideoCallStep,
  } = useVideoCallContext();

  const {
    handelPickIncomingVoiceCall,
    handelAudioCallStep,
    handelLeaveCall,
    handelVoiceCallUpdateListner,
    handelEndIncomingVoiceCallToFriebase
  } = useAudioCallContext();
  const { photo, name } = useAppSelector(
    (state) => state.currentChat
  );
  const { status, callType , channel} = useAppSelector((state) => state.currentCall);

  const navigate = useNavigate();
  const [showCallingScreen, setShowCallingScreen] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
     

      setShowCallingScreen(false);
      // handelEndSelfCallToFirebase();
  

      setTimeout(() => {
        if (status !== CALL_STATUS.Answered && callType != 1) {
          // navigate('/chat');
        
        }
      }, 500);
    }, 30000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
if(!channel){
  // navigate('/chat')
}
    handelVoiceCallUpdateListner();
  }, []);
  
  return (
    <div>
      {showCallingScreen ? (
        <div className="loader-container calling-section">
          <div className="spinner-wrap">
            <div className="spinner-item"></div>
            <div className="spinner-item spinner-item--2"></div>
            <div className="spinner-item spinner-item--3"></div>
            <img src={photo} alt="" />
          </div>
          <div
            className="calling-section-footer"
            onClick={onLeaveCall}
          >
            <i className="ri-phone-line"></i>
          </div>
        </div>
      ) : (
        <div className="loader-container calling-section">
          <p>{name} is not responding...</p>
        </div>
      )}
    </div>
  );
};

export default AudioCallWitingScreen;
