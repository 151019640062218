import axios from "axios";
import { server } from "../config/config";

export const logOutUser = async (identifier: any) => {
  try {
    const { data } = await axios.get(
      `${server}/users/logOutUser?identifier=${identifier}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};
