import React from "react";
import { lazy } from "react";
import MainLayout from "../components/Layouts/MainLayout";
const Home = lazy(() => import("../pages/IndexPage"));
const routes = [
  {
    path: "/",
    element: <MainLayout><Home /></MainLayout>,
  }
];

export default routes;