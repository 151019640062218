/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getFileSizeKB } from "../../../_utils";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
function MediaAttachment(props: any) {
  const MAX_FILE_SIZE = props.maxFileSizeInKb;
  const [files, setFiles] = useState([]);
  const [modalShow, setModalShow] = useState<any>(false);
  const fileInputRef = useRef(null);
  useEffect(() => {
    clearFileUpload();
  }, []);
  const clearFileUpload = () => {
    setFiles([]);
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ""; // This is the line that clears the file input
    }
  };
  useEffect(() => {
    if (!files.length) {
      setModalShow(false);
    }
  }, [files]);
  const removeFile = (index: any) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    if (fileInputRef && fileInputRef.current && files.length === 1) {
      fileInputRef.current.value = ""; // This is the line that clears the file input
    }
  };

  const onPickFile = (e: any) => {
    e.preventDefault();
    document.getElementById(props?.name).click();
  };

  const getFilesForUpload = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("attachments[]", file.file);
    });

    return files.length > 0 ? formData : null;
  };
  const uploadMedia = () => {
    let attachments = getFilesForUpload();
    if (!attachments) {
      return;
    }
    props.handelGetFiles(attachments);
    handelMediaModelClose();
  };
  const onFilePicked = (e: any) => {
    if (files.length > 10 || e.target.files.length > 10) {
      return;
    }
    for (const key of Object.keys(e.target.files)) {
      if (getFileSizeKB(e.target.files[key].size) > MAX_FILE_SIZE) {
        alert(
          `choose file is greater then maximum limited size ${getFileSize(MAX_FILE_SIZE)}`
        );
        return;
      }
      setModalShow(!modalShow);
      let fileDetails = {
        fileName: e.target.files[key].name,
        fileSize: getFileSize(e.target.files[key].size),
        fileType: getFileType(e.target.files[key]).toLowerCase(),
        file: e.target.files[key],
      };
      let fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[key]);
      setFiles((prevValues) => [...prevValues, fileDetails]);
    }
  };

  function getFileSize(bytes: any, decimals: any = 2) {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  const getFileSizeKB = (fileSize: number) => {
    fileSize = fileSize / 1024;
    return fileSize;
  };

  const getFileType = (file: any) => {
    return file?.type.split("/").pop();
  };
  const handelMediaModelClose = () => {
    clearFileUpload();
    setModalShow(false);
  };
  return (
    <Fragment>
      <div onClick={(e) => onPickFile(e)}>
        <Tooltip id='share' />
        <i className="ri-attachment-line" data-tooltip-id='share'
          data-tooltip-content='File'
          data-tooltip-place="bottom" />
      </div>
      <div className="document-list">
        {files && (
          <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="attachment-modal"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Upload Media
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="document-in-chat">
                {files.map((file, fileIndex) => {
                  return (
                    <Fragment key={fileIndex}>
                      <div>
                        <FileIcon
                          key={fileIndex}
                          extension={file.fileType}
                          {...defaultStyles[file.fileType]}
                        />
                        <label className="label label-primary">
                          {file.fileName}
                        </label>
                        <label className="label label-info">
                          {file.fileSize}
                        </label>
                        <i
                          className="ri-close-line"
                          onClick={() => {
                            removeFile(fileIndex);
                          }}
                        ></i>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <>
                <Button variant="primary" onClick={() => uploadMedia()}>
                  Upload
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handelMediaModelClose()}
                >
                  Close
                </Button>
              </>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Tooltip id="share" />
      <input
        className="file d-none"
        type="file"
        accept="image/jpeg, image/gif, image/png, application/pdf, audio/mpeg"
        data-show-upload="true"
        data-show-caption="true"
        id={props?.name}
        name={props?.name}
        required={props?.required ? true : false}
        onChange={(e) => onFilePicked(e)}
        multiple={props.multiple}
        ref={fileInputRef}
        data-tooltip-id="share"
        data-tooltip-content="Emoji"
        data-tooltip-place="top"
      />
    </Fragment>
  );
}

export default MediaAttachment;
