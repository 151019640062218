import { useState, createContext, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { CALL_NATURE, CALL_STATUS, CALL_TYPE, TYPE, success } from "../_utils";
import { layout } from "../agora-ui-kit";
import { useUnMount } from "../_hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { nanoid } from "nanoid";

// import { useAppDispatch } from "../redux/Hooks";
import { useAppSelector, useAppDispatch } from "../redux/Hooks";
import {
  createNewGroupCall,
  createNewIndividualCall,
  createNewIndividualVoiceCall,
  leaveCallService,
  pickIncomingCallServiece,
} from "../_services";
import {
  clearCallDetails,
  clearGroupCallDetails,
  createIncomingCall,
  createOutGoingGroupCall,
  createOutgoingCall,
  setAnsweredCall,
  setCurrentOutgoingCallId,
} from "../redux/actions/user";
import { database } from "../_utils/firebase";
import {
  limitToLast,
  off,
  onChildAdded,
  onValue,
  orderByKey,
  query,
  ref,
} from "firebase/database";
export interface RtcProps {
  appId: string;
  channel: string;
  token: string | null;
  role: "host";
  layout: number;
  enableScreensharing: boolean;
  callType: "Audio" | "Audio" | "Meeting" | "Broadcast";
  enableAudio?: boolean;
  enableVideo?: boolean;
}

export interface RtmProps {
  username: string;
  displayUsername: boolean;
}

export interface AudioCallContextProps {
  joined: boolean;
  callOnChannelName: string;
  audioCallStep: number;
  setCallOnChannelName: React.Dispatch<React.SetStateAction<string>>;
  setIsPartner: React.Dispatch<React.SetStateAction<boolean>>;
  getAudioCallURL: () => string;
  handelAudioCallStep: (value: number) => void;
  handelInitNewIndividualvOICECallToFirebase: () => void;
  handelEndSelfVoiceCallToFirebase: () => void;
  sharedcallOnChannelName: string | null;
  handelSetContactList: (contacts: any) => void;
  handelJoinCallAsHost: () => void;
  handelPickIncomingVoiceCall: (callObj) => void;
  handelVoiceCallUpdateListner: () => void;
  handelLeaveCall: () => void;
  handelInitNewVoiceGroupCallToFirebase:()=> void;
  handelEndIncomingVoiceCallToFriebase: () => void;
  rtcProps: RtcProps;
  rtmProps: RtmProps;
}

export const AudioCallContext = createContext<
  AudioCallContextProps | undefined
>(undefined);

export default function AudioCallProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const {
    uid: callerId,
    name,
    phone,
  } = useSelector((state: any) => state.user);
  const groupCall = useAppSelector((state) => state.currentGroupCall);
  const navigate = useNavigate();
  const APP_ID = "fe59ad8be52e430eaacc2da8f60d3cac";
  const TOKEN = null;
  let [queryString, setQueryString] = useSearchParams();
  const [audioCallStep, setAudioCallStep] = useState<number>(1);
  const [joined, setJoined] = useState<boolean>(false);
  const [callOnChannelName, setCallOnChannelName] =
    useState<string>("audiocall");
  const groupCallUsers: any[] = Object.values(groupCall);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const sharedcallOnChannelName = queryString.get("meeting");
  const [contactList, setContactList] = useState<any>([]);

  const {
    uid: toId,
    userType,
    photo,
    groupUsers,
  } = useAppSelector((state) => state.currentChat);
  const {
    callId: reduxCallId,
    toId: reduxToId,
    callerId: reduxIncomingCallerId,
    channel: reduxChannel,
    type,
  } = useAppSelector((state) => state.currentCall);
  const callIdd = useAppSelector((state) => state.currentCall);
  const [rtcProps, setRtcProps] = useState<RtcProps>({
    appId: "",
    channel: "",
    token: null,
    role: "host",
    layout: 0,
    enableScreensharing: false,
    callType: "Audio",
    enableVideo: false,
  });
  const [rtmProps, setRtmProps] = useState<RtmProps>({
    username: "",
    displayUsername: false,
  });
  const handelAudioCallStep = (value: number) => {
    setAudioCallStep(value);
  };

  const initCall = async () => {
    try {
      setRtcProps({
        appId: APP_ID,
        channel: callOnChannelName,
        token: TOKEN,
        role: "host",
        layout: layout.grid,
        enableScreensharing: false,
        callType: "Audio",
        enableVideo: false,
      });

      setRtmProps({ username: name, displayUsername: false });
      setJoined(true);
    } catch (error) {
      // console.log("Error: initCall", error);
      navigate("/chat");
    }
  };

  const getAudioCallURL = () => {
    const href = window.location.origin;
    const url = `${href}/audio-call?calling=${callOnChannelName}&type=${userType}`;
    return url;
  };

  const handelJoinCallAsHost = () => {
    handelAudioCallStep(2);
  };

  const handelLeaveCall = async () => {
  dispatch(clearCallDetails(CLEAR_CALL_DATA));
  dispatch(clearGroupCallDetails());
  handelAudioCallStep(1);
  setJoined(false);
  setTimeout(() => {
    navigate("/chat");
  }, 100);
  };

  useEffect(() => {
    if (sharedcallOnChannelName) {
      setCallOnChannelName(sharedcallOnChannelName);
      setIsPartner(true);
    }
  }, []);

  useUnMount(() => {
    if (joined) {
      handelLeaveCall();
    }
  });

  useEffect(() => {
    
    // if (callOnChannelName && isPartner) {
    //   handelAudioCallStep(2);
    // } else {
    //   handelAudioCallStep(1);
    // }
  }, [callOnChannelName, setAudioCallStep]);

  useEffect(() => {
    if (audioCallStep === 2) {
      initCall();
    }
  }, [audioCallStep]);

  const handelInitNewIndividualvOICECallToFirebase = async () => {
    try {
      // Generate a unique token for the call channel using nanoid
      const initCalToken = nanoid();
      // Create an object containing details of the outgoing call
      let currentCallObj = {
        toId: toId,
        callerId: callerId,
        name: name || "Unknown",
        photo: photo || "",
        channel: initCalToken, // channel
        type: CALL_TYPE.Outgoing,
        timestamp: new Date().getTime(),
        status: CALL_STATUS.Ringing,
        callType: TYPE.AUDIO_CALL_INDIVIDUAL,
        callNature: userType,
      };
      // Dispatch an action to update the Redux state with the outgoing call details
      dispatch(createOutgoingCall(currentCallObj));
      // Create a new call record in Firebase
      const callRecordForFirebase = {
        callType: TYPE.AUDIO_CALL_INDIVIDUAL,
        callerId: callerId,
        channel: initCalToken,
        phoneNumber: phone,
        timestamp: new Date().getTime(),
        toId: toId,
        status: CALL_STATUS.Ringing,
      };
      // Call the asynchronous function to create a new call record
      const createCallResult = await createNewIndividualVoiceCall(
        callRecordForFirebase
      );
      // Dispatch an action to set the current outgoing call ID in the Redux state
      dispatch(setCurrentOutgoingCallId({ callId: createCallResult.callId }));
      // Navigate to the video call page with the generated token and user type
      navigate(`/audio-call?type=${userType}`);
    } catch (error) {
      // Log and handle errors
      // console.error("handelInitNewIndividualCallToFirebase", error);
      throw new Error("An error occurred during call initiation.");
    }
  };

  const CLEAR_CALL_DATA = {
    toId: "",
    callerId: "",
    callId: "",
    name: "",
    photo: "",
    channel: "",
    type: "",
    timestamp: "",
    status: "",
    callType: "",
    phoneNumber: "",
    callNature: "",
  };
  const handelIncomingCallFirebaseNodeListner = () => {
    const userCallRef = ref(database, `userCalls/${callerId}`); //my id
    let lastChildKey = null;
    const getLastChildKey = () => {
      return new Promise((resolve, reject) => {
        const lastChildQuery = query(userCallRef, orderByKey(), limitToLast(1));
        onValue(lastChildQuery, (snapshot) => {
          if (snapshot.exists()) {
            const keys = Object.keys(snapshot.val());
            lastChildKey = keys.length > 0 ? keys[0] : null;
            resolve(lastChildKey);
          } else {
            reject("Snapshot does not exist");
          }
        });
      });
    };
    const handleChildAdded = async (snapshot) => {
      try {
        const incomingCall = snapshot.val();
        if (
          snapshot.key === lastChildKey &&
          incomingCall.callId !== reduxCallId
        ) {
          const value = await getLastCallSnapshotValue(
            incomingCall.callerId,
            incomingCall.toId
          );
          if (
            value[incomingCall.callId].status !== CALL_STATUS.Cancelled &&
            value[incomingCall.callId].status !== CALL_STATUS.Disconnect &&
            !value[incomingCall.callId].ended_outgoing &&
            !value[incomingCall.callId].ended_incoming
          ) {
            const matchingUser = await contactList.find(
              (user) => user.userId === incomingCall.callerId
            );

          

            const incomingCallObject = {
              type: CALL_TYPE.Incoming,
              name: matchingUser?.info?.name || "Unknown Name",
              photo: matchingUser?.info?.photo || "",
              ...value[incomingCall.callId],
            };

            dispatch(createIncomingCall(incomingCallObject));
          } else {
            dispatch(clearCallDetails(CLEAR_CALL_DATA));
          }
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };
    // Call getLastChildKey to initialize lastChildKey
     getLastChildKey().catch(function(error) {
      // console.error("Error:", error);
  });
    // Set up the child_added listener
    onChildAdded(userCallRef, handleChildAdded);

    // Return cleanup function
    return () => {
      off(userCallRef, "child_added", handleChildAdded);
    };
  };

  const getLastCallSnapshotValue = async (callerId, toId) => {
    const userCallRef = ref(database, `newCalls/${toId}/${callerId}`);

    return new Promise((resolve, reject) => {
      const lastChildQuery = query(userCallRef, orderByKey(), limitToLast(1));

      onValue(lastChildQuery, (snapshot) => {
        if (snapshot.exists()) {
          const lastChild = snapshot.val();
          resolve(lastChild);
        } else {
          reject("Snapshot does not exist");
        }
      });
    });
  };

  const isGroupCall = () => groupCallUsers.length > 0;

  const handelSetContactList = (contacts: any) => {
    setContactList(contacts);
  };

  //Call listener for updating all types of calls
  const handelVoiceCallUpdateListner = () => {
    alert('1')
    if(!isGroupCall()){
    const userCallRef = ref(
      database,
      `newCalls/${reduxToId}/${reduxIncomingCallerId}/${reduxCallId}`
    );

    const handleValueChanged = (snapshot: any) => {
      const updatedCall = snapshot.val();
      if (updatedCall) {
        if (
          updatedCall.status === CALL_STATUS.Cancelled ||
          updatedCall.status === CALL_STATUS.Disconnect
        ) {
          updatedCall.status === CALL_STATUS.Cancelled
            ? toast("Call Rejected")
            : toast("Call ended");

          handelLeaveCall();
        } else if (updatedCall.status === CALL_STATUS.Answered ) {
          handelPickIncomingVoiceCall(updatedCall);
        }
      }
    };
    onValue(userCallRef, handleValueChanged);
    return () => {
      off(userCallRef, "value", handleValueChanged);
    };
  }
    if (isGroupCall()) {
      alert('2')
      let groupCallListeners = [];
      // Loop through each user in group to set up listeners
      groupCallUsers.forEach((data: any) => {
        const groupCallRef = ref(
          database,
          `newCalls/${data.toId}/${data.callerId}/${data.callId}`
        );
        const handleValueChanged = (snapshot: any) => {
          const updatedGroupCall = snapshot.val();
          if (updatedGroupCall) {
            if (updatedGroupCall.status === CALL_STATUS.Answered) {
              handelPickIncomingVoiceCall(updatedGroupCall);
            }
          }
        };
        onValue(groupCallRef, handleValueChanged);
        const listenerInfo = {
          ref: groupCallRef,
          handler: handleValueChanged,
        };
        groupCallListeners.push(listenerInfo);
      });
      // Cleanup: Remove listeners when they are no longer needed
      return () => {
        groupCallListeners.forEach((listenerInfo) => {
          const { ref, handler } = listenerInfo;
          off(ref, "value", handler);
        });
      };
    }
  };

  //Pick Calls
  const handelPickIncomingVoiceCall = async (updatedCall) => {
    try {
      await pickIncomingCallServiece({
        callId: updatedCall.callId,
        callerId: updatedCall.callerId,
        toId: updatedCall.toId,
        status: CALL_STATUS.Answered,
        callNature: updatedCall.callNature,
      }).then((res) => {
           dispatch(setAnsweredCall({ ...updatedCall }));
        handelAudioCallStep(2);
        navigate(`/audio-call?type=${userType}`);
      });
    } catch (error) {
      throw error;
    }
    return;
  };

  //Drop the incoming call
  const handelEndIncomingVoiceCallToFriebase = async () => {
    try {
      await leaveCallService({
        callId: reduxCallId,
        status: CALL_STATUS.Cancelled,
        callerId: reduxIncomingCallerId,
        toId: reduxToId,
        callNature: CALL_NATURE.Individual,
      }).then((res) => {
        handelLeaveCall();
      });
    } catch (error) {
      // console.error("handelEndIncomingCallToFirebase", error);
      throw new Error("An error occurred during call rejection.");
    }
    return;
  };

  //Drop the outgoing call
  const handelEndSelfVoiceCallToFirebase = async () => {
    try {
      if (isGroupCall()) {
        const leaveCallPromises = groupCallUsers.map(async (data: any) => {
         
          
          return leaveCallService({
            callId: data.callId,
            status: CALL_STATUS.Disconnect,
            callerId: data.callerId,
            toId: data.toId,
            callNature: CALL_NATURE.Group,
          });
        });
        try {
          await Promise.all(leaveCallPromises);
          handelLeaveCall();
        } catch (error) {
          // console.error("Error leaving calls:", error);
        }
      }

      if (!isGroupCall()) {
        await leaveCallService({
          callId: reduxCallId,
          status: CALL_STATUS.Disconnect,
          callerId: reduxIncomingCallerId,
          toId: reduxToId,
          callNature: CALL_NATURE.Individual,
        }).then((res) => {
          handelLeaveCall();
        });
      }
    } catch (error) {
      throw error;
    }
    return;
  };


    const handelInitNewVoiceGroupCallToFirebase = async () => {
      try {
        if (userType !== CALL_NATURE.Group) {
          return;
        }
        // Generate a unique token for the call channel using nanoid
        const initCalToken = nanoid();
        let firebaseCallObject = [];
        let reduxCallObject = [];
        // Create an object containing details of the outgoing call
        let usersInGroup = Object.keys(groupUsers).filter(
          (id) => id !== callerId
        );
        for (let i = 0; i < usersInGroup.length; i++) {
          const toIdGroupUser = usersInGroup[i];
          const resultObject = contactList.find(
            (item) => item.userId === toIdGroupUser
          );
          let currentCallObj = {
            toId: toIdGroupUser,
            callerId: callerId,
            name: resultObject?.info?.name || "Unknown",
            photo: resultObject?.info?.photo || "",
            channel: initCalToken, // channel
            type: CALL_TYPE.Outgoing,
            timestamp: new Date().getTime(),
            status: CALL_STATUS.Ringing,
            callType: TYPE.AUDIO_CALL_GROUP,
            callNature: userType,
          };
          const callRecordForFirebase = {
            callType: TYPE.AUDIO_CALL_GROUP,
            callerId: callerId,
            channel: initCalToken,
            phoneNumber: phone,
            timestamp: new Date().getTime(),
            toId: toIdGroupUser,
            status: CALL_STATUS.Ringing,
            callNature: userType,
          };
          firebaseCallObject.push(callRecordForFirebase);
          reduxCallObject.push(currentCallObj);
          if (i === usersInGroup.length - 1) {
            const createCallResult = await createNewGroupCall(
              firebaseCallObject
            );
            if (createCallResult) {
              let result = reduxCallObject.map((objOne) => {
                // Find the corresponding object in the 'two' array
                let matchingObjTwo = createCallResult?.uniqueKeysWithToId.find(
                  (objTwo) => objTwo.toId === objOne.toId
                );
                // If a match is found, add the 'name' property to the object in 'one'
                if (matchingObjTwo) {
                  return { ...objOne, callId: matchingObjTwo.callId };
                } else {
                  // If no match is found, return the original 'one' object
                  return objOne;
                }
              });
              dispatch(createOutGoingGroupCall(result));
              navigate(`/audio-call?type=${userType}`);
            }
          }
        }
      } catch (error) {
        // Log and handle errors
        // console.error("handelInitNewIndividualCallToFirebase", error);
        throw new Error("An error occurred during call initiation.");
      }
    };

  return (
    <AudioCallContext.Provider
      value={{
        joined,
        callOnChannelName,
        audioCallStep,
        setCallOnChannelName,
        handelVoiceCallUpdateListner,
        handelInitNewIndividualvOICECallToFirebase,
        handelEndSelfVoiceCallToFirebase,
        setIsPartner,
        getAudioCallURL,
        handelAudioCallStep,
        sharedcallOnChannelName,
        handelJoinCallAsHost,
        handelLeaveCall,
        handelSetContactList,
        handelPickIncomingVoiceCall,
        rtcProps,
        rtmProps,
        handelEndIncomingVoiceCallToFriebase,
        handelInitNewVoiceGroupCallToFirebase,
      }}
    >
      {children}
    </AudioCallContext.Provider>
  );
}

export const useAudioCallContext = () => {
  const context = useContext(AudioCallContext);
  if (!context) {
    throw new Error(
      "useAudioCallContext must be used within a AudioCallProvider"
    );
  }
  return context;
};
