import React, { useContext, useState } from 'react'
import EndCall from './Local/EndCall'
import LocalAudioMute from './Local/LocalAudioMute'
import Screenshare from './Local/Screenshare'
import LocalVideoMute from './Local/LocalVideoMute'
import PropsContext from '../PropsContext'
import { useMeetingContext } from "../../_contexts/MeetingContext";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CopyLinks from "../../components/meeting/common/CopyLinks";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
function LocalControls() {
  const { styleProps, rtcProps } = useContext(PropsContext)
  const { localBtnContainer } = styleProps || {}
  const {
    getMeetingURL
  } = useMeetingContext();
  const showSwal = () => {
    withReactContent(Swal).fire({
      title: <CopyLinks url={getMeetingURL().url} />,
      text: "Share link with other so they can join you",
      allowEnterKey: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  return (
    <div
      style={{
        ...{
          backgroundColor: '#2cb45f',
          width: '100%',
          height: 50,
          zIndex: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        },
        ...localBtnContainer
      }}
    >
      {rtcProps.role !== 'audience' && <LocalVideoMute />}
      {rtcProps.role !== 'audience' && <LocalAudioMute />}
      {rtcProps.role !== 'audience' && rtcProps.enableScreensharing && (
        <Screenshare />
      )}
      {rtcProps.role !== 'audience' && (rtcProps.callType !== "Video") && (rtcProps.callType !== "Audio") && (
       <>  <Tooltip id='share' />
        <i onClick={showSwal} className="ri-share-line copy-btn"    data-tooltip-id='share'
        data-tooltip-content='share link'
        data-tooltip-place="top"></i>
        </>
      )}
      <EndCall />
    </div>
  )
}

export default LocalControls
