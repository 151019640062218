import axios from "axios";
import { server } from "../config/config";

export const createMetting = async (createrData: any) => {
  try {
    const { data } = await axios.post(
      `${server}/users/create-meeting`,
      createrData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const joinMeeting = async (meetingId: any, userData: any) => {
  try {
    const { data } = await axios.put(
      `${server}/users/join-meeting?meetingId=${meetingId}`,
      userData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

// Start Call Service

// {r responsable object
//     "callType": 1,
//     "callerId": "2f4aXsCUM5Og6ylxkEcLzfGmqtC3",
//     "channel": "29323112-4daa-4900-a49e-5126e5f769ce",
//     "phoneNumber": "+66840856495",
//     "timestamp": 1693962969303,
//     "toId": "1nAC7T03DkPpowFyj5YXRgAWn212"
// }
export const createNewIndividualCall = async (callObj: any) => {
  try {
    const { data } = await axios.post(`${server}/users/new-call`, callObj);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createNewIndividualVoiceCall = async (callObj: any) => {
  

  try {
    const { data } = await axios.post(
      `${server}/users/create-audio-call`,
      callObj
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createNewGroupCall = async (callObj: any) => {
  try {
    

    const { data } = await axios.post(
      `${server}/users/new-group-call`,
      callObj
    );
    return data;
  } catch (error) {
    throw error;
  }
};
// Leave Call Service

// {
//     "callId":"-Nl6p6af0TQAz2gfKFhL",
//     "newKey":"ended_incoming",
//     "newKey":"ended_outgoing",
//     "newValue":true,
//     "callerId": "2f4aXsCUM5Og6ylxkEcLzfGmqtC3",
//     "toId": "1nAC7T03DkPpowFyj5YXRgAWn212"
// }=> {

export const leaveCallService = async (callObj: any) => {
  try {
    const { data } = await axios.put(`${server}/users/update-call`, callObj);
    return data;
  } catch (error) {
    throw error;
  }
};

export const pickIncomingCallServiece = async (callObj: any) => {
  try {
    

    const { data } = await axios.put(`${server}/users/update-call`, callObj);
    return data;
  } catch (error) {
    throw error;
  }
};
