import React, { useState, useEffect, useRef, PropsWithChildren } from 'react'
import { RtcPropsInterface, mediaStore } from './PropsContext'
import { useNavigate } from "react-router-dom";
import {
  ILocalVideoTrack,
  ILocalAudioTrack,
  createMicrophoneAndCameraTracks,
} from 'agora-rtc-react'
import { TracksProvider } from './TracksContext'
import Swal from 'sweetalert2';

const useTracks = createMicrophoneAndCameraTracks(
  { encoderConfig: {} },
  { encoderConfig: {} }
)
/**
 * React component that create local camera and microphone tracks and assigns them to the child components
 */
const TracksConfigure: React.FC<PropsWithChildren<Partial<RtcPropsInterface>>> = (props) => {
  const [ready, setReady] = useState<boolean>(false)
  const [localVideoTrack, setLocalVideoTrack] =
    useState<ILocalVideoTrack | null>(null)
  const [localAudioTrack, setLocalAudioTrack] =
    useState<ILocalAudioTrack | null>(null)
  const { ready: trackReady, tracks, error } = useTracks()
  const mediaStore = useRef<mediaStore>({})
  const navigate = useNavigate();
  useEffect(() => {
    if (tracks !== null) {
      setLocalAudioTrack(tracks[0])
      setLocalVideoTrack(tracks[1])
      mediaStore.current[0] = {
        audioTrack: tracks[0],
        videoTrack: tracks[1]
      }
      setReady(true)
    } else if (error) {
      setReady(false)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please check your audio/video access before proceeding.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Back"

      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/chat')
        }
      });
    }
    return () => {
      if (tracks) {
        // eslint-disable-next-line no-unused-expressions
        tracks[0]?.close()
        // eslint-disable-next-line no-unused-expressions
        tracks[1]?.close()
      }
    }
  }, [trackReady, error]) //, ready])

  return (
    <TracksProvider value={{
        localVideoTrack: localVideoTrack,
        localAudioTrack: localAudioTrack
      }}>{ready ? props.children : null}</TracksProvider>
  )
}

export default TracksConfigure
