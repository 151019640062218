import React, { Fragment, useState } from "react";
import Contact from "./Contact";
import Group from "./Group";
import ContactSkeleton from "./ContactSkeleton";
import { useChatContext } from "../../../_contexts/ChatContext";

const ContactsList: React.FC<any> = React.memo(
  ({ displayUsers, contactLoading }) => {
    const [selectedContact, setSelectedContact] = useState();
    const { allMessages, allGroupMessages, addMessage, initChat } =
      useChatContext();

    return (
      <div className="chat-list">
        {contactLoading && (
          <>
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
            <ContactSkeleton />
          </>
        )}

        {!contactLoading &&
          displayUsers
            ?.sort((a, b) => {
              const getLastMessageTimestamp = (contact) => {
                if (contact.type === "user") {
                  return Array.isArray(allMessages[contact.userId])
                    ? allMessages[contact.userId][
                        allMessages[contact.userId].length - 1
                      ]?.timestamp
                    : 0;
                } else if (contact.type === "group") {
                  return Array.isArray(allGroupMessages[contact.key])
                    ? allGroupMessages[contact.key][
                        allGroupMessages[contact.key].length - 1
                      ]?.timestamp
                    : 0;
                }
                return 0;
              };
              return getLastMessageTimestamp(b) - getLastMessageTimestamp(a);
            })
            ?.map((contact) => {
              if (contact.type == "user") {
                return (
                  <Fragment key={contact.userId}>
                    <Contact
                      userId={contact.userId}
                      userDetails={contact.info}
                      // latestMessage={contact.latestMessage}
                      latestMessage={
                        Array.isArray(allMessages[contact.userId]) &&
                        allMessages[contact.userId][
                          allMessages[contact.userId].length - 1
                        ]
                      }
                      activeContact={selectedContact === contact.userId}
                      setSelectedContact={setSelectedContact}
                    />
                  </Fragment>
                );
              } else if (contact.type == "group") {
                return (
                  <Fragment key={contact.key}>
                    <Group
                      groupId={contact.key}
                      groupUsers={contact.users}
                      groupDetails={contact.info}
                      activeContact={selectedContact === contact.key}
                      setSelectedContact={setSelectedContact}
                      latestMessage={
                        Array.isArray(allGroupMessages[contact.key]) &&
                        allGroupMessages[contact.key][
                          allGroupMessages[contact.key].length - 1
                        ]
                      }
                    />
                  </Fragment>
                );
              }
            })}
      </div>
    );
  }
);

export default ContactsList;
