import React, { useEffect } from "react";
import { useAppSelector } from "../../../redux/Hooks";

import { off, onChildAdded, ref } from "firebase/database";
import { useChatContext } from "../../../_contexts/ChatContext";
import { database } from "../../../_utils/firebase";

const DefaultScreen = () => {
  const { uid, name, photo } = useAppSelector((state) => state.user);
  const { allMessages, addMessage, addGroupMessage } = useChatContext();
  useEffect(() => {
    const userMessageRef = ref(database, `userMessages/${uid}`);
    const handleChildAdded = (snapshot: any) => {
      const newRecord = snapshot.val();
     
      
      if (Object.keys(allMessages).length < 1) {
        if (newRecord && newRecord.isGroup) {
          addGroupMessage(newRecord.toId, {
            ...newRecord,
            key: newRecord.messageId,
            fromPhone: newRecord.phone,
            timestamp: parseInt(newRecord.timestamp),
          });
        } else {
          addMessage(newRecord.fromId, {
            ...newRecord,
            key: newRecord.messageId,
            timestamp: parseInt(newRecord.timestamp),
          });
        }
      }
    };
    if (Object.keys(allMessages).length > 0)
      onChildAdded(userMessageRef, handleChildAdded);
    return () => {
      off(userMessageRef, "child_added");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages]);
  return (
    <div className="preview-window">
      <div className="inner-content">
        <img className="app-logo" src={photo} alt="" />
        <h2>Welcome {name}, to NZ chat web</h2>
        <p>
          Make a call, share your screen and get a faster experience with NZ
          Chat for web.
        </p>
      </div>
    </div>
  );
};

//     const { allMessages, addMessage, addGroupMessage } = useChatContext();
//     useEffect(() => {
//         const userMessageRef = ref(database, `userMessages/${uid}`);
//         const handleChildAdded = (snapshot: any) => {
//             const newRecord = snapshot.val();

//             // if(Object.keys(allMessages).length < 1)return
//             if (newRecord && newRecord.isGroup) {

//                 addGroupMessage(newRecord.toId, { ...newRecord, key: newRecord.messageId, fromPhone: newRecord.phone, timestamp: parseInt(newRecord.timestamp) });
//             }
//             else {
//                 addMessage(newRecord.fromId, { ...newRecord, key: newRecord.messageId, timestamp: parseInt(newRecord.timestamp) });
//             }
//         };
//         if (Object.keys(allMessages).length > 0)
//             onChildAdded(userMessageRef, handleChildAdded);
//         return () => {
//             off(userMessageRef, 'child_added');
//         };
//     }, [allMessages]);
//     return (
//         <div className="preview-window">
//             <div className="inner-content">
//                 {/* <img className="app-logo" src="images/app_logo.png" alt="" /> */}
//                 <img className="app-logo" src={photo} alt="" />
//                 <h1>Welcome {name}, now use NZ-Chat on your computer</h1>
//                 <p>Make a calls, share your screen and get a faster experience with NZ Chat for web.</p>
//                 <button>Happy Suffering😊</button>
//             </div>
//         </div>
//     )
// }

// export default DefaultScreen
export default DefaultScreen;
