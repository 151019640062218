import React, { useEffect, useState } from "react";
import { setCurrentChat } from "../../../redux/actions/user";
import { useAppDispatch } from "../../../redux/Hooks";
import { convertTimestamp } from "../../../_utils";
import { decription } from "../../../_utils/cryptography";
import { getFileFromFireStorage } from "../../../_utils/firebase";

const Group: React.FC<any> = ({
  groupId,
  groupDetails,
  setSelectedContact,
  activeContact,
  latestMessage,
  groupUsers,
}) => {
  const dispatch = useAppDispatch();
  const [finalMessage, setFinalMessage] = useState<string>("");
  const [file, setFile] = useState<string>("");

  const openGroupChat = (groupId: string, groupDetails: any) => {
    let currentChatObj = {
      userId: groupId,
      name: groupDetails.name,
      photo: groupDetails?.photo,
      groupUsers: groupUsers,
      userType: "group",
    };
    dispatch(setCurrentChat(currentChatObj));
    setSelectedContact(groupId);
  };
  const removeHtmlTags = (inputString) => {
    if (!inputString) {
      return;
    }
    return inputString.replace(/<[^>]*>/g, "");
  };
  useEffect(() => {
    decription(latestMessage?.content).then((content) => {
      if (latestMessage && latestMessage?.type === 33) {
        if (latestMessage?.fileSize) {
          setFile(content);
        } else {
          getFileFromFireStorage(content)
            .then((filePath) => {
              setFile(filePath);
            })
            .catch((error) => {
              setFile("");
            });
        }
      } else if (latestMessage && latestMessage?.type === 1) {
        setFinalMessage(content);
      } else {
        setFinalMessage(content);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMessage?.content, latestMessage?.type]);

  

  return (
    <div
      className={activeContact ? "chat-box active" : "chat-box"}
      onClick={() => openGroupChat(groupId, groupDetails)}
    >
      <div className="img-box">
        <img className="img-cover" src={groupDetails?.photo} alt="" />
      </div>
      <div className="chat-details">
        <div className="text-head">
          <h4>{groupDetails.name}</h4>
          <p className="time unread">
            {latestMessage &&   latestMessage.timestamp &&
              convertTimestamp(latestMessage.timestamp)}
          </p>
        </div>
        {latestMessage && (
          <div className="text-message inline-message">
            {latestMessage?.type === 33
              ? "Sticker"
              : finalMessage?.length > 20
              ? removeHtmlTags(finalMessage).slice(0, 30).includes("/image")
                ? "Image"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/video")
                ? "Video"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/audio/")
                ? "sent an audio file"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/file/")
                ? "sent a file"
                : `${removeHtmlTags(finalMessage).slice(0, 30)}...`
              : removeHtmlTags(finalMessage)}
          </div>
        )}
      </div>
    </div>
  );
};
export default Group;
