import { useState } from "react";
import { useAppSelector } from "../../../redux/Hooks";
import { GroupMemberList } from "./GroupMembersList";
import { useVideoCallContext } from "../../../_contexts/VideoCallContext";
import { CALL_NATURE, CALL_TYPE } from "../../../_utils";
import { useAudioCallContext } from "../../../_contexts/AudioCallContext";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const ChatHeader = () => {
  const {
    handelInitNewIndividualCallToFirebase,
    handelInitNewGroupCallToFirebase,
  } = useVideoCallContext();
  const {
    handelInitNewIndividualvOICECallToFirebase,
    handelInitNewVoiceGroupCallToFirebase,
  } = useAudioCallContext();
  const { name, photo, userType } = useAppSelector(
    (state) => state.currentChat
  );
  const [isModalShow, setIsModalShow] = useState(false);
  const handleCloseModal = () => { 
    setIsModalShow(false);
  };

  return (
    <div className="header">
      {userType === "group" && (
        <GroupMemberList isShow={isModalShow} closeModal={handleCloseModal} />
      )}


{userType == "user" && (
        <GroupMemberList isShow={isModalShow} closeModal={handleCloseModal} />
      )}


      <div
        className="img-text"
        onClick={() => {
  setIsModalShow(true);
        }}
      >
        <div className="user-img">
          <img className="dp" src={photo} alt="" />
        </div>
        <h4 >
          {name}
          {/* Leo */}
          <br />
          <span>Online</span>
        </h4>
      </div>
      <ul className="nav-icons">
        <li
          onClick={() => {
             userType === CALL_NATURE.Individual ?  handelInitNewIndividualvOICECallToFirebase(): handelInitNewVoiceGroupCallToFirebase();
          }}
        >
    <Tooltip id='share' />
          <i className="ri-phone-line"  data-tooltip-id='share'
        data-tooltip-content='Voice Call'
        data-tooltip-place="top"></i>
        </li>
        <li>
          <Tooltip id='share' />
          <i
            onClick={() => {
              userType === CALL_NATURE.Individual
                ? handelInitNewIndividualCallToFirebase()
                : handelInitNewGroupCallToFirebase();
            }}
            className={`ri-vidicon-line `}
 data-tooltip-id='share'
        data-tooltip-content='Video Call'
        data-tooltip-place="top"
          ></i>
        </li>
      </ul>
    </div>
  );
};
export default ChatHeader;
