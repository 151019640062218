
import React, { Fragment, useEffect, useState, useRef } from "react";
import ChatMessage from "./ChatMessage";
import { database, getGroupMessages } from "../../../_utils/firebase";
import GroupMessage from "./GroupMessage";
import { useAppSelector } from "../../../redux/Hooks";
import Input from "./InputBox";
import { off, onChildAdded, ref } from "firebase/database";
import { useChatContext } from "../../../_contexts/ChatContext";
import Skeleton from "react-loading-skeleton";
import { MessageSekelton } from "./chstMessageSekelton";
import {
  VideoCallContext,
  useVideoCallContext,
} from "../../../_contexts/VideoCallContext";
const ChatBody = ({ allMessages }) => {
  const { uid } = useAppSelector((state) => state.user);
  const { addMessage, addGroupMessage } = useChatContext();
  const messageBoxEndRef = useRef<HTMLInputElement | null>(null);
  const { uid: currentChaterId, userType } = useAppSelector(
    (state) => state.currentChat
  );

  const { setshowEmoji, setShowEmojiPicker, showAllmsg } = useChatContext();

  // const scrollToBottom = () => {
  //   messageBoxEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  function scrollDivToBottom() {
    const myDiv = document.getElementById("chatContainer");

    if (myDiv) {
      const divScrollHeight = myDiv.scrollHeight;
      const divClientHeight = myDiv.clientHeight;
      let currentScroll = 0;

      const scrollInterval = setInterval(() => {
        myDiv.scrollTop += 1000000000;

        // Update the current scroll position
        currentScroll += 100000000;

        // Check if we have reached the bottom
        if (currentScroll >= divScrollHeight - divClientHeight) {
          clearInterval(scrollInterval);
        }
      }, 10); // Adjust the interval as needed
    }
  }

  // Call the function to scroll the div to the bottom

  // Call the function to scroll to the bottom

  const { handelEndIncomingCallToFirebase } = useVideoCallContext();

  useEffect(scrollDivToBottom, [allMessages, showAllmsg]);
  useEffect(() => {
    const userMessageRef = ref(database, `userMessages/${uid}`);
    const handleChildAdded = (snapshot: any) => {
      const newRecord = snapshot.val();
      if (allMessages) {
        if (newRecord && newRecord.isGroup) {
          addGroupMessage(newRecord.toId, {
            ...newRecord,
            key: newRecord.messageId,
            fromPhone: newRecord.phone,
            timestamp: parseInt(newRecord.timestamp),
            type: parseInt(newRecord.type),
          });
        } else {
          addMessage(newRecord.fromId, {
            ...newRecord,
            key: newRecord.messageId,
            timestamp: parseInt(newRecord.timestamp),
            type: parseInt(newRecord.type),
          });
        }
      }
    };
    onChildAdded(userMessageRef, handleChildAdded);
    return () => {
      off(userMessageRef, "child_added");
    };
  }, []);
  // Use a Set to filter out duplicate messages based on the "content" key
  const uniqueMessages = Array.from(new Set(allMessages.map(message => message.key))).map(key => {
    return allMessages.find(message => message.key === key);
  });

  return (
    <>
      <div
        id="chatContainer"
        className="chat-container"
        onClick={() => {
          setShowEmojiPicker(false);
        }}
      >
        {userType === "user" &&
          allMessages?.length > 0 &&
          uniqueMessages.map((message, index) => (
            <Fragment key={index}>
              <ChatMessage
                message={message}
                userId={uid}
                currentChaterId={currentChaterId}
              />
            </Fragment>
          ))}
        {!allMessages && <MessageSekelton />}
        {userType === "group" &&
          uniqueMessages?.map((message, index) => {
            return (
              <Fragment key={index}>
                <GroupMessage message={message} userId={uid} />
              </Fragment>
            );
          })}
        <div ref={messageBoxEndRef}></div>
      </div>
      <Input userId={uid} currentChaterId={currentChaterId} />
    </>
  );
};

export default ChatBody;
