/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useEffect } from "react";
import { layout } from "../agora-ui-kit";
import { useUnMount } from "../_hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/Hooks";
import { off, onChildAdded, orderByKey, limitToLast, onValue, ref, query } from "@firebase/database";
import { createNewGroupCall, createNewIndividualCall, leaveCallService, pickIncomingCallServiece } from "../_services";
import { useAppSelector } from "../redux/Hooks";
import { CALL_NATURE, CALL_STATUS, CALL_TYPE, TYPE } from "../_utils/";
import { setAnsweredCall, createOutgoingCall, setCurrentOutgoingCallId, createIncomingCall, clearCallDetails, createOutGoingGroupCall, clearGroupCallDetails, setAnsweredGroupCall } from "../redux/actions/user";
import { toast } from "react-hot-toast";
import {
  database,
} from "../_utils/firebase";
import { nanoid } from "nanoid";
export interface RtcProps {
  appId: string;
  channel: string;
  token: string | null;
  role: "host";
  layout: number;
  enableScreensharing: boolean;
  callType: "Video" | "Audio" | "Meeting" | "Broadcast";
}
export interface RtmProps {
  username: string;
  displayUsername: boolean;
}
export interface VideoCallContextProps {
  joined: boolean;
  videoCallStep: number;
  handelVideoCallStep: (value: number) => void;
  handelLeaveCall: () => void;
  handelIncomingCallFirebaseNodeListner: () => void;
  handelInitNewGroupCallToFirebase: () => void;
  handelSetContactList: (contacts: any) => void;
  handelInitNewIndividualCallToFirebase: () => void;
  handelEndSelfCallToFirebase: () => void;
  handelEndIncomingCallToFirebase: () => void;
  handelPickIncomingCall: (callObject) => void;
  handelCallUpdateListner: () => void;
  rtcProps: RtcProps;
  rtmProps: RtmProps;
}

export const VideoCallContext = createContext<
  VideoCallContextProps | undefined
>(undefined);
export default function VideoCallProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()
  // const { uid, name } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const APP_ID = "fe59ad8be52e430eaacc2da8f60d3cac";
  const TOKEN = null;
  const [videoCallStep, setVideoCallStep] = useState<number>(1);
  const [joined, setJoined] = useState<boolean>(false);
  const [contactList, setContactList] = useState<any>([]);
  
  const [rtcProps, setRtcProps] = useState<RtcProps>({
    enableScreensharing: false,
    callType: "Video",
    channel: "",
    token: null,
    role: "host",
    appId: "",
    layout: 0,
  });
  
  const [rtmProps, setRtmProps] = useState<RtmProps>({
    username: "",
    displayUsername: false,
  });
 
  const { uid: callerId, phone } = useAppSelector(state => state.user)
  const { uid: toId, userType, photo, name, groupUsers } = useAppSelector(state => state.currentChat)
  
  const groupCall = useAppSelector(state => state.currentGroupCall)
  const groupCallUsers: any[] = Object.values(groupCall);
  const { callId: reduxCallId, toId: reduxToId, callerId: reduxIncomingCallerId, channel: reduxChannel, callNature: callNatureRedux } = useAppSelector(state => state.currentCall)
  
  const CLEAR_CALL_DATA = {
    toId: "",
    callerId: "",
    callId: "",
    name: "",
    photo: "",
    channel: "",
    type: "",
    timestamp: "",
    status: "",
    callType: "",
    phoneNumber: "",
    callNature: ""
  }
 
  const handelSetContactList = (contacts: any) => {
    setContactList(contacts)
  }
 
  const handelVideoCallStep = (value: number) => {
    setVideoCallStep(value);
  };
 
  const isGroupCall = () => groupCallUsers.length > 0;
  /**
 * Initiates a new outgoing call, updates the Redux state with call details,
 * creates a new call record in Firebase, and navigates to the video call page.
 *
 * @throws {Error} If there is an error during the process.
 */
  const handelInitNewIndividualCallToFirebase = async () => {
    try {
      // Generate a unique token for the call channel using nanoid
      const initCalToken = nanoid();
      // Create an object containing details of the outgoing call
      let currentCallObj = {
        toId: toId,
        callerId: callerId,
        name: name || "Unknown",
        photo: photo || "",
        channel: initCalToken, // channel
        type: CALL_TYPE.Outgoing,
        timestamp: new Date().getTime(),
        status: CALL_STATUS.Ringing,
        callType: TYPE.VIDEO_CALL_INDIVIDUAL,
        callNature: userType
      };
      // Dispatch an action to update the Redux state with the outgoing call details
      dispatch(createOutgoingCall(currentCallObj));
      // Create a new call record in Firebase
      const callRecordForFirebase = {
        callType: TYPE.VIDEO_CALL_INDIVIDUAL,
        callerId: callerId,
        channel: initCalToken,
        phoneNumber: phone,
        timestamp: new Date().getTime(),
        toId: toId,
        status: CALL_STATUS.Ringing,
        callNature: userType
      };
      // Call the asynchronous function to create a new call record
      const createCallResult = await createNewIndividualCall(callRecordForFirebase);
      // Dispatch an action to set the current outgoing call ID in the Redux state
      dispatch(setCurrentOutgoingCallId({ callId: createCallResult.callId }));
      // Navigate to the video call page with the generated token and user type
      navigate(`/video-call?token=${initCalToken}&type=${userType}`);
    } catch (error) {
      // Log and handle errors
      // console.error("handelInitNewIndividualCallToFirebase", error);
      throw new Error("An error occurred during call initiation.");
    }
  }
  const handelInitNewGroupCallToFirebase = async () => {
    try {
      if (userType !== CALL_NATURE.Group) { return; }
      // Generate a unique token for the call channel using nanoid
      const initCalToken = nanoid();
      let firebaseCallObject = [];
      let reduxCallObject = []
      // Create an object containing details of the outgoing call
      let usersInGroup = Object.keys(groupUsers).filter((id) => id !== callerId)
      for (let i = 0; i < usersInGroup.length; i++) {
        const toIdGroupUser = usersInGroup[i];
        const resultObject = contactList.find(item => item.userId === toIdGroupUser);
        let currentCallObj = {
          toId: toIdGroupUser,
          callerId: callerId,
          name: resultObject?.info?.name || "Unknown",
          photo: resultObject?.info?.photo || "",
          channel: initCalToken, // channel
          type: CALL_TYPE.Outgoing,
          timestamp: new Date().getTime(),
          status: CALL_STATUS.Ringing,
          callType: TYPE.VIDEO_CALL_GROUP,
          callNature: userType
        };
        const callRecordForFirebase = {
          callType: TYPE.VIDEO_CALL_GROUP,
          callerId: callerId,
          channel: initCalToken,
          phoneNumber: phone,
          timestamp: new Date().getTime(),
          toId: toIdGroupUser,
          status: CALL_STATUS.Ringing,
          callNature: userType
        }
        firebaseCallObject.push(callRecordForFirebase)
        reduxCallObject.push(currentCallObj)
        if (i === usersInGroup.length - 1) {
          const createCallResult = await createNewGroupCall(firebaseCallObject);
          if (createCallResult) {
            let result = reduxCallObject.map((objOne) => {
              // Find the corresponding object in the 'two' array
              let matchingObjTwo = createCallResult?.uniqueKeysWithToId.find((objTwo) => objTwo.toId === objOne.toId);
              // If a match is found, add the 'name' property to the object in 'one'
              if (matchingObjTwo) {
                return { ...objOne, callId: matchingObjTwo.callId };
              } else {
                // If no match is found, return the original 'one' object
                return objOne;
              }
            });
            dispatch(createOutGoingGroupCall(result));
            navigate(`/video-call?token=${initCalToken}&type=${userType}`);
          }
        }
      }
    } catch (error) {
      // Log and handle errors
      // console.error("handelInitNewIndividualCallToFirebase", error);
      throw new Error("An error occurred during call initiation.");
    }
  }

  /**
   * Handel the filrebase event to listing the last node of newCall tigger in firebase 
   * It also update the incoming cal object in redux
   * function refrence in window.tsx filte after fetching all contacts
   */
  const handelIncomingCallFirebaseNodeListner = () => {
    const userCallRef = ref(database, `userCalls/${callerId}`);//my id
    let lastChildKey = null;
    const getLastChildKey = () => {
      return new Promise((resolve, reject) => {
        const lastChildQuery = query(userCallRef, orderByKey(), limitToLast(1));
        onValue(lastChildQuery, (snapshot) => {
          if (snapshot.exists()) {
            const keys = Object.keys(snapshot.val());
            lastChildKey = keys.length > 0 ? keys[0] : null;
            resolve(lastChildKey);
          } else {
            reject("Snapshot does not exist");
          }
        });
      });
    };
    const handleChildAdded = async (snapshot) => {
      try {
        const incomingCall = snapshot.val();
        if (snapshot.key === lastChildKey && incomingCall.callId !== reduxCallId) {
          const value = await getLastCallSnapshotValue(incomingCall.callerId, incomingCall.toId);
          if (value[incomingCall.callId].status !== CALL_STATUS.Cancelled && value[incomingCall.callId].status !== CALL_STATUS.Disconnect && (!value[incomingCall.callId].ended_outgoing) && (!value[incomingCall.callId].ended_incoming)) {
            const matchingUser = await contactList.find((user) => user.userId === incomingCall.callerId);
            const incomingCallObject = {
              type: CALL_TYPE.Incoming,
              name: matchingUser?.info?.name || "Unknown Name",
              photo: matchingUser?.info?.photo || "",
              ...value[incomingCall.callId],
            };

            dispatch(createIncomingCall(incomingCallObject));
          } else {
            dispatch(clearCallDetails(CLEAR_CALL_DATA));
          }
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    
    // Call getLastChildKey to initialize lastChildKey
    getLastChildKey().catch((error) => console.error("Error:", error));
    // Set up the child_added listener
    onChildAdded(userCallRef, handleChildAdded);
    // Return cleanup function
    return () => {
      off(userCallRef, 'child_added', handleChildAdded);
    };
  };

  const getLastCallSnapshotValue = async (callerId, toId) => {
    const userCallRef = ref(database, `newCalls/${toId}/${callerId}`);

    return new Promise((resolve, reject) => {
      const lastChildQuery = query(userCallRef, orderByKey(), limitToLast(1));

      onValue(lastChildQuery, (snapshot) => {
        if (snapshot.exists()) {
          const lastChild = snapshot.val();
          resolve(lastChild);
        } else {
          reject("Snapshot does not exist");
        }
      });
    });
  };

  const initCall = async () => {
    try {
      setRtcProps({
        channel:reduxChannel,
        enableScreensharing: false,
        layout: layout.grid,
        callType: "Video",
        appId: APP_ID,
        token: TOKEN,
        role: "host",
      });
      setRtmProps({ username: name, displayUsername: false });
      setJoined(true);
    } catch (error) {
      // console.log("Error: initCall", error);
      navigate("/chat");
    }
  };
  //Drop the outgoing call
  const handelEndSelfCallToFirebase = async () => {
    try {
      if (isGroupCall()) {
        const leaveCallPromises = groupCallUsers.map(async (data: any) => {
          return leaveCallService({
            "callId": data.callId,
            "status": CALL_STATUS.Disconnect,
            "callerId": data.callerId,
            "toId": data.toId,
            "callNature": CALL_NATURE.Group
          });
        });
        try {
          await Promise.all(leaveCallPromises);
          handelLeaveCall();
        } catch (error) {
          // console.error("Error leaving calls:", error);
        }
      } 
      
      if (!isGroupCall()) {
        await leaveCallService({
          "callId": reduxCallId,
          "status": CALL_STATUS.Disconnect,
          "callerId": reduxIncomingCallerId,
          "toId": reduxToId,
          "callNature": callNatureRedux
        }).then((res) => {
          handelLeaveCall()
        })
      }
    }
    catch (error) {
      throw error
    }
    return;
  }
  //Drop the incoming call 
  const handelEndIncomingCallToFirebase = async () => {
    try {
   
      await leaveCallService({
        "callId": reduxCallId,
        "status": CALL_STATUS.Cancelled,
        "callerId": reduxIncomingCallerId,
        "toId": reduxToId,
        "callNature": callNatureRedux
      }).then((res) => {
        handelLeaveCall()
      })
    }
    catch (error) {
      // console.error("handelEndIncomingCallToFirebase", error);
      throw new Error("An error occurred during call rejection.");
    }
    return;
  }
  //Internal page routing when call is end by the user or auto by the system
  const handelLeaveCall = () => {
    dispatch(clearCallDetails(CLEAR_CALL_DATA));
    dispatch(clearGroupCallDetails());
    handelVideoCallStep(1)
    setJoined(false)
    setTimeout(() => {
      navigate("/chat");
    }, 100)
  }

  //Call listener for updating all types of calls  
  const handelCallUpdateListner = () => {
    if (!isGroupCall()) {
      const userCallRef = ref(database, `newCalls/${reduxToId}/${reduxIncomingCallerId}/${reduxCallId}`)
      const handleValueChanged = (snapshot: any) => {
        const updatedCall = snapshot.val();
        if (updatedCall) {
          if (updatedCall.status === CALL_STATUS.Cancelled || updatedCall.status === CALL_STATUS.Disconnect) {
            updatedCall.status === CALL_STATUS.Cancelled ? toast("Call Rejected") : toast("Call ended")
            handelLeaveCall()
          } else if (updatedCall.status === CALL_STATUS.Answered) {
            handelPickIncomingCall(updatedCall)
          }
        }
      };
      onValue(userCallRef, handleValueChanged);
      return () => {
        off(userCallRef, 'value', handleValueChanged);
      };
    }
    
    if(isGroupCall()){
      let groupCallListeners = []
      // Loop through each user in group to set up listeners
      groupCallUsers.forEach((data: any) => {
        const groupCallRef = ref(database, `newCalls/${data.toId}/${data.callerId}/${data.callId}`);
        const handleValueChanged = (snapshot: any) => {
          const updatedGroupCall = snapshot.val();
          if (updatedGroupCall) {
            if (updatedGroupCall.status === CALL_STATUS.Answered) {
              handelPickIncomingCall(updatedGroupCall);
            }
          }
        };
        onValue(groupCallRef, handleValueChanged);
        const listenerInfo = { ref: groupCallRef, handler: handleValueChanged };
        groupCallListeners.push(listenerInfo);
      });
      // Cleanup: Remove listeners when they are no longer needed
      return () => {
        groupCallListeners.forEach(listenerInfo => {
          const { ref, handler } = listenerInfo;
          off(ref, 'value', handler);
        });
      };
    }

  }
  //Pick Calls
  const handelPickIncomingCall = async (updatedCall) => {
    try {
      await pickIncomingCallServiece({
        "callId": updatedCall.callId,
        "callerId": updatedCall.callerId,
        "toId": updatedCall.toId,
        "status": CALL_STATUS.Answered,
        "callNature": updatedCall.callNature
      }).then((res) => {
        dispatch(setAnsweredCall({ ...updatedCall }));
        handelVideoCallStep(2)
        navigate("/video-call");
      })
    }
    catch (error) {
      throw error
    }
    return;
  }
  useEffect(() => {
    if (videoCallStep === 2) {
      initCall();
    }
  }, [videoCallStep]);
  return (
    <VideoCallContext.Provider
      value={{
        joined,
        videoCallStep,
        handelVideoCallStep,
        handelLeaveCall,
        handelIncomingCallFirebaseNodeListner,
        handelSetContactList,
        handelInitNewIndividualCallToFirebase,
        handelInitNewGroupCallToFirebase,
        handelEndSelfCallToFirebase,
        handelEndIncomingCallToFirebase,
        handelCallUpdateListner,
        handelPickIncomingCall,
        rtcProps,
        rtmProps,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
}

export const useVideoCallContext = () => {
  const context = useContext(VideoCallContext);
  if (!context) {
    throw new Error(
      "useVideoCallContext must be used within a VideoCallProvider"
    );
  }
  return context;
};
