import { Modal, Button } from "react-bootstrap";
export const ShowMediaModal = (props: any) => {
  const downloadImage = () => {
    const imageUrl = props.mediaVal.url;
    const link = document.createElement("a");
    link.href = imageUrl;
    link.target = "_blank";
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Modal
      className="img-video-modal"
      show={props?.mediaVal?.showModal}
      centered
    >
      <Modal.Header closeButton onClick={props.closeModal}></Modal.Header>
      <Modal.Body>
        <div className="download-section">
          <i
            className="ri-download-line"
            style={{ cursor: "pointer" }}
            onClick={downloadImage}
          ></i>
        </div>
        {props?.mediaVal?.mediaType === "image" && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img src={props.mediaVal.url} width={200} height={200} />
        )}

        {props?.mediaVal?.mediaType === "video" && (
          <video width="320" height="240" controls>
            <source src={props?.mediaVal?.url} />
          </video>
        )}
      </Modal.Body>
    </Modal>
  );
};
