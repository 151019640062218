import React, { createContext, useContext, useEffect, useState } from "react";
import { webSocketServer } from "../config/config";

interface WebSocketContextProps {
  webSocket: WebSocket | null;
  disconnectSocket: () => void;
  setSocketConnection: (path:any) => void;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  const setSocketConnection = (path:any) => {
    console.log(path);
    const ws = new WebSocket(`${webSocketServer}${path}`);
    setWebSocket(ws);
  };

  const disconnectSocket = () => {
    if (webSocket) {
      webSocket.close();
    }
  };

  return (
    <WebSocketContext.Provider value={{ webSocket, disconnectSocket, setSocketConnection }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocketContext() {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocketContext must be used within a WebSocketProvider");
  }
  return context;
}
