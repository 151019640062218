/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import "../../../styles/Input.css";
import { encryption } from "../../../_utils/cryptography";
import Picker from "emoji-picker-react";
import MediaAttachment from "./MediaAttachment";
import {
  getMobileFcm,
  sendGroupMessage,
  sendMessage,
  uploadFile,
} from "../../../_utils/firebase";
import { useChatContext } from "../../../_contexts/ChatContext";
import { useAppSelector } from "../../../redux/Hooks";
import { getFileSizeKB } from "../../../_utils";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import axios from 'axios'
import { useSelector } from "react-redux";

const Input = ({ userId, currentChaterId }) => {
  const writerInputRef = useRef<HTMLTextAreaElement | null>(null);
  const {
    addMessage,
    addGroupMessage,
    editMessage, 
    isEditMessageAction,
    handleUpdateMessage,
    editType,
    isChatOpen,
    setIsChatOpen,
  } = useChatContext();
  const [message, setMessage] = useState("");
  const { userType } = useAppSelector((state) => state.currentChat);
  const { phone } = useAppSelector((state) => state.user);
  const { showEmojiPicker, setShowEmojiPicker } = useChatContext();
  // height for input box
  useEffect(()=>{
     setMessage(" ")
  },[isChatOpen])

  const maxTextareaHeight = 150;
  useEffect(() => {
    setMessage(isEditMessageAction ? editMessage?.message : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMessageAction]);


  const getSelfMsg= (fcm , completeMsgPayload) => {
    let url = 'https://nz-chat.com/api/users/getselfMessage'
    let payload = {
      fcm :fcm,
      msgObject :(completeMsgPayload)
    }
    axios.put(url , payload)
    .then(response => {
     
      // Handle response data here
    })
    .catch(error => {
      // console.error('Error:', error);
      // Handle errors here
    });
  }

  const onSubmitTextMessage = async (e: any) => {
    e.preventDefault();
    const timestamp = new Date().getTime();
    let messageText = message.trim();
    messageText = messageText.replace(/<p><br><\/p>/g, "");
    messageText = messageText.replace(/<br>$/g, "");
    if (messageText === "") {
      return;
    }
    const ecryptedMsg = await encryption(messageText);
    if (userType === "user") {
      const newMessage = {
        content: ecryptedMsg,
        encryptionType: "AES",
        fromId: userId,
        toId: currentChaterId,
        type: 1,
        timestamp: timestamp,
        encryptionVersion: 1,
      };
      // Save in firebase
      if (!isEditMessageAction) {
        const msgKey = await sendMessage(newMessage);
        await addMessage(currentChaterId, { ...newMessage, key: msgKey });
        let fcm = await getMobileFcm(userId)
      
        
        fcm && getSelfMsg(fcm , {...newMessage , messageId : msgKey , phone})
       
      }
    } else if (userType === "group") {
      const newMessage = {
        content: ecryptedMsg,
        encryptionType: "AES",
        fromId: userId,
        fromPhone: phone,
        type: 1,
        timestamp: timestamp,
        encryptionVersion: 1,
      };
      // Save in firebase
      if (!isEditMessageAction) {
        let msgKey = await sendGroupMessage(currentChaterId, newMessage);
        await addGroupMessage(currentChaterId, { ...newMessage, key: msgKey });
      }
    }

    setMessage("");
    setShowEmojiPicker(false);
    writerInputRef.current.focus();
  };
  const adjustTextareaHeight = () => {
    const textarea = writerInputRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(
        textarea.scrollHeight,
        maxTextareaHeight
      )}px`;
      textarea.style.overflowY =
        textarea.scrollHeight > maxTextareaHeight ? "auto" : "hidden";
    }
  };

  // const adjustTextareaHeight = () => {
  //   const textarea = writerInputRef.current;
  //   const emojiPickerHeight = 100;/* Set the height of your emoji picker card */ // Set the actual height of your emoji picker card in pixels
  //   const spaceAboveTextarea = 10; // Set the desired space above the textarea
  
  //   if (textarea) {
  //     textarea.style.height = "auto";
  //     const newHeight = Math.min(textarea.scrollHeight, maxTextareaHeight);
  //     const maxHeightWithSpace = maxTextareaHeight - emojiPickerHeight - spaceAboveTextarea;
  
  //     textarea.style.height = `${Math.min(newHeight, maxHeightWithSpace)}px`;
  //     textarea.style.overflowY = newHeight > maxHeightWithSpace ? "auto" : "hidden";
  //   }
  // };
  
  const onEnterPress =async (event: any) => {

    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();

      if(!isEditMessageAction){
        onSubmitTextMessage(event)
      }
       else{
        handleUpdateMessage(editMessage, message, editType);
       } 
      setShowEmojiPicker(false);
    }
    if (event.keyCode === 13 && event.shiftKey === true) {
      adjustTextareaHeight();
    }
  };

  const handleEmojiClick = (emoji: any) => {
    const textarea = writerInputRef.current;
    if (textarea) {
      const { selectionStart, selectionEnd, value } = textarea;
      const newMessage =
        value.substring(0, selectionStart) +
        emoji.emoji +
        value.substring(selectionEnd);
      setMessage(newMessage);
      const newCaretPosition = selectionStart + emoji.emoji.length;
      textarea.setSelectionRange(newCaretPosition, newCaretPosition);
      // textarea.focus();
    }
  };
  const handelGetFiles = (files: any) => {
    files.forEach(async (file: any) => {
      const timestamp = new Date().getTime();
      let filePath = await uploadFile(file);
      let newMessage = {};
      if (userType === "user") {
        if (filePath.fileLocation === "image") {
          const ecryptedMsg = await encryption(
            `/${filePath.fileLocation}/${filePath.uniqueFileName}`
          );
          newMessage = {
            content: ecryptedMsg,
            encryptionType: "AES",
            encryptionVersion: 1,
            fromId: userId,
            metadata: getFileSizeKB(file.size),
            thumb: "",
            timestamp: timestamp,
            toId: currentChaterId,
            type: 2,
          };
   
        } else if (filePath.fileLocation === "video") {
          const ecryptedMsg = await encryption(
            `/${filePath.fileLocation}/${filePath.uniqueFileName}`
          );
          newMessage = {
            content: ecryptedMsg,
            encryptionType: "AES",
            encryptionVersion: 1,
            fromId: userId,
            mediaDuration: "00:00",
            metadata: getFileSizeKB(file.size),
            thumb: "",
            timestamp: timestamp,
            toId: currentChaterId,
            type: 5,
          };
        } else if (filePath.fileLocation === "file") {
          const ecryptedMsg = await encryption(
            `/${filePath.fileLocation}/${filePath.uniqueFileName}`
          );
          newMessage = {
            content: ecryptedMsg,
            encryptionType: "AES",
            encryptionVersion: 1,
            fromId: userId,
            fileSize: getFileSizeKB(file.size),
            metadata: file.name,
            timestamp: timestamp,
            toId: currentChaterId,
            type: 13,
          };
        }
        // Save in DB
        const msgKey = await sendMessage(newMessage);
        // Save in conmessage
        
        
        await addMessage(currentChaterId, { ...newMessage, key: msgKey });
        let fcm = await getMobileFcm(userId)
        fcm && getSelfMsg(fcm , {...newMessage , messageId : msgKey , phone})
      }
    });
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);
  return (
    <>
      <div className="chatbox-input" id="'root-emoji">
        <div className="emoji">
          {showEmojiPicker == true && (
            <Picker onEmojiClick={handleEmojiClick} />
          )}
          <Tooltip id="share" />
          <i
            className="ri-user-smile-line"
            onClick={() => {
              setShowEmojiPicker(true);
            }}
            data-tooltip-id="share"
            data-tooltip-content="Emoji"
            data-tooltip-place="left"
          ></i>
        </div>

        <MediaAttachment
          handelGetFiles={handelGetFiles}
          name="create-docs-upload"
          maxFileSizeInKb="23483023"
          type="file"
          multiple={true}
        />
        <textarea
          ref={writerInputRef}
          className={`w-100 mob-order-1 messagebox`}
          value={message}
          onChange={(event) => {
            let updatedMessage = event.target.value.replace(
              /<p><br><\/p>/g,
              ""
            );
            setMessage(updatedMessage);
        
          }}
          onKeyDown={onEnterPress}
        ></textarea>
        {message.replace(/<p><br><\/p>/g, "") !== "" && (
          <i
            className="ri-send-plane-fill"
            onClick={(e) =>
              !isEditMessageAction
                ? onSubmitTextMessage(e)
                : handleUpdateMessage(editMessage, message, editType)
            }
          ></i>
        )}
      </div>
    </>
  );
};

export default Input;
