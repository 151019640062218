/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import {
  success
} from "../_utils";
import { layout } from '../agora-ui-kit'
import { useUnMount } from "../_hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { joinMeeting } from "../_services";
import { nanoid } from "nanoid"
export interface RtcProps {
  appId: string;
  channel: string;
  token: string | null;
  role: 'host' | 'audience'; // Adjust role types based on your application logic
  layout: number;
  enableScreensharing: boolean;
}

export interface RtmProps {
  username: string;
  displayUsername: boolean;
}

export interface MeetingContextProps {
  joined: boolean;
  meetingName: string;
  meetingStep: number;
  setMeetingName: React.Dispatch<React.SetStateAction<string>>;
  setIsSharedMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  getMeetingURL: () => { node: React.ReactNode; url: string };
  handelMeetingStep: (value: number) => void;
  sharedMeetingName: string | null;
  handelJoinMeetingAsHost: () => void;
  handelLeaveMeeting: () => void;
  rtcProps: RtcProps;
  rtmProps: RtmProps;
}

export const MeetingContext = createContext<MeetingContextProps | undefined>(undefined);

export default function MeetingProvider({ children }: { children: React.ReactNode }) {
  const { uid, name } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const APP_ID = "fe59ad8be52e430eaacc2da8f60d3cac";
  const TOKEN = null;
  let [queryString, setQueryString] = useSearchParams();
  const [meetingStep, setMeetingStep] = useState<number>(1);
  const [joined, setJoined] = useState<boolean>(false);
  const [meetingName, setMeetingName] = useState<string>("");
  const [isSharedMeeting, setIsSharedMeeting] = useState<boolean>(false);
  const sharedMeetingName = queryString.get("meeting");
  // const [rtcProps, setRtcProps] = useState<RtcProps>({});
  const [rtcProps, setRtcProps] = useState<RtcProps>({
    appId: "",
    channel: "",
    token: null,
    role: "host", // or "audience" based on your logic
    layout: 0, // or another suitable default value
    enableScreensharing: false,
  });
  const [rtmProps, setRtmProps] = useState<RtmProps>({ username: "", displayUsername: false });
  const handelMeetingStep = (value: number) => {
    setMeetingStep(value);
  };
  const initMeeting = async () => {
    try {
      // const res = await joinMeeting(meetingName, {
      //   "inMeetingRoom": true,
      //   "muteCamera": true,
      //   "muteSpeaker": true,
      //   "profileName": name,
      //   "uid": uid
      // });

      setRtcProps({
        appId: APP_ID,
        channel: meetingName,
        token: TOKEN,
        role: 'host', // Update with your logic, use 'res.userRole' if needed
        layout: layout.grid,
        enableScreensharing: true
      });

      setRtmProps({ username:name, displayUsername: true });
      setJoined(true);
    } catch (error) {
      console.log("Error: initMeeting", error);
      navigate("/chat");
    }
  };

  const getMeetingURL = () => {
    const href = window.location.origin;
    const url = `${href}/meeting?meeting=${meetingName}`;
    const node = (
      <span>
        You can invite others to join this meeting by sharing the URL below.{" "}
        <a href={url} target="_blank" rel="noreferrer">
          here
        </a>
      </span>
    );
    return { node, url };
  };

  const handelJoinMeetingAsHost = () => {
    handelMeetingStep(3);
    setQueryString({ meeting: meetingName });
  };

  const handelLeaveMeeting = async () => {
    setJoined(false);
    window.location.href = '/chat';
  };

  useEffect(() => {
    if (sharedMeetingName) {
      setMeetingName(sharedMeetingName);
      setIsSharedMeeting(true);
    }
  }, []);

  useUnMount(() => {
    if (joined) {
      handelLeaveMeeting();
    }
  });

  useEffect(() => {
    if (meetingName && isSharedMeeting) {
      handelMeetingStep(3);
    } else if (meetingName && !isSharedMeeting) {
      toast.success(success.meetingCreated.title);
      handelMeetingStep(2);
    } else {
      handelMeetingStep(1);
    }
  }, [meetingName, setMeetingStep]);

  useEffect(() => {
    if (meetingStep === 3) {
      initMeeting();
    }
  }, [meetingStep]);

  return (
    <MeetingContext.Provider
      value={{
        joined,
        meetingName,
        meetingStep,
        setMeetingName,
        setIsSharedMeeting,
        getMeetingURL,
        handelMeetingStep,
        sharedMeetingName,
        handelJoinMeetingAsHost,
        handelLeaveMeeting,
        rtcProps,
        rtmProps
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
}

export const useMeetingContext = () => {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error("useMeetingContext must be used within a VideoCallProvider");
  }
  return context;
};
