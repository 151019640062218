import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { updateUserProfile } from "../../redux/actions/user";

const ProfileInputBox = ({ title, dbKey }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { uid, name, status } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (dbKey === "name") {
      setValue(name);
    }
    if (dbKey === "status") {
      setValue(status);
    }
  }, []);

  const handleUpdate = async () => {
    const data = {
      [dbKey]: value,
    };
    dispatch(updateUserProfile(uid, data));
    setIsDisabled(true);
  };

  return (
    <div className="label-section">
      <label>
        {title}
        {isDisabled ? (
          <i
            className="ri-pencil-fill"
            onClick={() => setIsDisabled(false)}
          ></i>
        ) : (
          <i
            className="ri-check-line"
            onClick={() => {
              value.length > 0 ? handleUpdate() : alert("Field can't be empty");
            }}
          ></i>
        )}
      </label>
      <input
        type="text"
        value={value}
        disabled={isDisabled}
        id="profile-name"
        style={
          isDisabled
            ? {
                borderBottom: "2px solid white",
                color: "#878787",
              }
            : {
                borderBottom: "2px solid #2cb45f",
                color: "rgb(191 191 191)",
              }
        }
        onChange={(e) => {
          if (dbKey != "status") {
            if (e.target.value.length > 25) {
              alert("Name can't be more than 25 letters");
            } else {
              setValue(e.target.value);
            }
          } else {
            if (e.target.value.length > 100) {
              alert("About me can’t be more than 100 letters");
            } else {
              setValue(e.target.value);
            }
          }
        }}
      />
    </div>
  );
};

export default ProfileInputBox;
