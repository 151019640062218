/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMeetingContext } from "../../_contexts/MeetingContext";
import { createMetting } from "../../_services";
import { useAppSelector } from "../../redux/Hooks";
import {useNavigate} from "react-router-dom";

interface FormData {
  meetingName: string;
}

const CreateMeetingForm: React.FC = () => {
  const navigate = useNavigate();
  const { uid, name } = useAppSelector((state: any) => state.user);

  const validationSchema = Yup.object().shape({
    meetingName: Yup.string()
      .required("Please provide valid meeting name")
       .min(5, "Meeting name should be at least 5 characters.")
      .max(30, "Meeting name is too large - should be 30 characters maximum.")
      .matches(
        /^[A-Za-z0-9_\s]*$/,
        "Meeting name can only contain Latin letters, numbers, and space _ ."
      ),
  });

  const formOptions:any = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm<FormData>(formOptions);
  const { errors } = formState;
  const { setMeetingName } = useMeetingContext();

  const onSubmit: SubmitHandler<FormData> = async ({ meetingName }) => {
    try {
      // setMeetingName(meetingName);
      const res = await createMetting({
        creatoruid: uid,
        name: name,
        createTime: Date.now(),
        meetingName,
      });
     
      setMeetingName(res.meetingId);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div className="card-wrapper">
        <Card>
          <Card.Body>
            <Card.Title>{"Create a meeting"}</Card.Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("meetingName")}
                  type="text"
                  placeholder="Enter your Meeting Name"
                />
                <Form.Text className="text-danger">
                  {errors.meetingName?.message}
                </Form.Text>
              </Form.Group>
              <div className="d-flex justify-content-between">
              <Button type="button" variant="info" onClick={()=>{navigate('/chat')}}>
              <i className="ri-arrow-left-s-line"></i> Back
              </Button>
              <Button type="submit" variant="success">
                <i className="ri-video-chat-line"></i> Next
              </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default CreateMeetingForm;
