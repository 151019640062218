/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useVideoCallContext } from "../../../_contexts/VideoCallContext";
import { useAppSelector } from '../../../redux/Hooks';
import { database } from '../../../_utils/firebase';
import { off, onValue, ref } from 'firebase/database';
import { CALL_STATUS, CALL_TYPE } from '../../../_utils';
interface CallScreenProps {
  onLeaveCall: () => void;
}
const CallWaitingScrren: React.FC<CallScreenProps> = ({ onLeaveCall }) => {
  let [queryString, setQueryString] = useSearchParams();
  const callTypeInURL = queryString.get("type");

  const { handelEndSelfCallToFirebase, handelCallUpdateListner, handelVideoCallStep } = useVideoCallContext();
  //one to one call
  const { photo, name } = useAppSelector((state) => state.currentChat);
  const { status } = useAppSelector(state => state.currentCall);

  const groupCallRedux = useAppSelector(state => state.currentGroupCall)

  const navigate = useNavigate();
  const [showCallingScreen, setShowCallingScreen] = useState(true);
  useEffect(() => {
    interface GroupCallParticipant {
      callId: string;
      callNature: string;
      callType: number;
      callerId: string;
      channel: string;
      name: string;
      photo: string;
      status: string;
      timestamp: number;
      toId: string;
      type: string;
    };
    let groupCallParticipants:GroupCallParticipant[]= Object.values(groupCallRedux);
    let timeoutIdUser = null;
    let timeoutIdGroup = null;
    if (callTypeInURL === "user") {
      timeoutIdUser = setTimeout(() => {
        setShowCallingScreen(false);
        handelEndSelfCallToFirebase();
        setTimeout(() => {
          if (status !== CALL_STATUS.Answered) {
            navigate('/chat');

          }
        }, 500);
      }, 30000);
    }
    if (callTypeInURL === "group") {
      timeoutIdGroup = setTimeout(() => {
        // Check if any object has type "answer"
      const hasAnswer = groupCallParticipants.some(obj => obj?.status === "answer");
        setShowCallingScreen(false);
        handelEndSelfCallToFirebase();
        setTimeout(() => {
          if (!hasAnswer) {
            navigate('/chat');
          }
        }, 500);
      }, 30000);
    }

    return () => {clearTimeout(timeoutIdUser);clearTimeout(timeoutIdGroup)};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handelCallUpdateListner()
  }, [])
  return (
    <div>
      {showCallingScreen ? (
        <div className="loader-container calling-section">
          <div className="spinner-wrap">
            <div className="spinner-item"></div>
            <div className="spinner-item spinner-item--2"></div>
            <div className="spinner-item spinner-item--3"></div>
            <img
              src={photo}
              alt=""
            />
          </div>
          <div className="calling-section-footer">
            <i className="ri-phone-line" onClick={onLeaveCall}></i>
          </div>
        </div>
      ) : (
        <div className="loader-container calling-section">
          <p>{name} is not responding...</p>
        </div>
      )}
    </div>
  );
};
export default CallWaitingScrren;
