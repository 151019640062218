/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useVideoCallContext } from "../_contexts/VideoCallContext";
import AgoraUIKit from '../agora-ui-kit'
import CallWaitingScrren from "../components/meeting/common/CallWaitingScrren"
import { useAppSelector } from "../redux/Hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CALL_TYPE, CALL_STATUS, TYPE, CALL_NATURE } from "../_utils"
import toast from "react-hot-toast";
const VideoCall = () => {
  const {
    videoCallStep,
    joined,
    rtcProps,
    rtmProps,
    handelEndSelfCallToFirebase
  } = useVideoCallContext();
  const { type, calltoken, status,callNature } = useAppSelector(state => state.currentCall)
  let [queryString, setQueryString] = useSearchParams();
  const callTypeInURL = queryString.get("type");
  return (

    <>
      {(callTypeInURL === CALL_NATURE.Individual || callNature === CALL_NATURE.Individual) &&
        <>
          {videoCallStep === 1 && status === CALL_STATUS.Ringing && (
            <>
              <CallWaitingScrren onLeaveCall={() => { handelEndSelfCallToFirebase() }} />
            </>
          )}
          {videoCallStep === 2 && status === CALL_STATUS.Answered && rtcProps && rtmProps && joined && <>
            <div style={styles.container}>
              <AgoraUIKit
                rtcProps={rtcProps}
                rtmProps={rtmProps}
                callbacks={{
                  EndCall: () => { handelEndSelfCallToFirebase() },
                }}
              />
            </div>
          </>}
        </>
      }
      {(callTypeInURL === CALL_NATURE.Group || callNature === CALL_NATURE.Group) && (<>
        {videoCallStep === 1 && (
          <>
            <CallWaitingScrren onLeaveCall={() => { handelEndSelfCallToFirebase() }} />
          </>
        )}
        {videoCallStep === 2 && <>
          <div style={styles.container}>
            <AgoraUIKit
              rtcProps={rtcProps}
              rtmProps={rtmProps}
              callbacks={{
                EndCall: () => { handelEndSelfCallToFirebase() },
              }}
            />
          </div>
        </>}
      </>)}
    </>
  );
};
const styles = {
  container: { width: "100vw", height: "100vh", display: "flex", flex: 1 },
};
export default VideoCall;
 