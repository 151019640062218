
import React, { useEffect, useState } from "react";
import { convertTimestamp } from "../../../_utils";
import { setCurrentChat } from "../../../redux/actions/user";
import { useAppDispatch } from "../../../redux/Hooks";
import { decription } from "../../../_utils/cryptography";
import { getFileFromFireStorage } from "../../../_utils/firebase";
import { useChatContext } from "../../../_contexts/ChatContext";

const Contact: React.FC<any> = ({
  userId,
  userDetails,
  latestMessage,
  setSelectedContact,
  activeContact,
}) => {
  const dispatch = useAppDispatch();
  const {

    setIsChatOpen,
  } = useChatContext();
  const openUserChat = (
    userId: string,
    userDetails: { name?: string; photo?: string }
  ) => {
    let currentChatObj = {
      userId: userId,
      name: userDetails?.name || "",
      photo: userDetails?.photo || "",
      userType: "user",
    };
    dispatch(setCurrentChat(currentChatObj));
    setSelectedContact(userId);
    setIsChatOpen(Math.random())
  };
  const removeHtmlTags = (inputString) => {
    if (!inputString) {
      return;
    }
    return inputString.replace(/<[^>]*>/g, "");
  };
  const [finalMessage, setFinalMessage] = useState<string>("");
  const [file, setFile] = useState<string>("");

  useEffect(() => {
    decription(latestMessage?.content).then((content) => {
      if (latestMessage && latestMessage?.type === 33) {
       
        if (latestMessage?.fileSize) {
          setFile(content);
        } else {
          getFileFromFireStorage(content)
            .then((filePath) => {
              setFile(filePath);
            })
            .catch((error) => {
              setFile("");
            });
        }
      } else if (latestMessage && latestMessage?.type === 1) {
        setFinalMessage(content);
      } else {
        setFinalMessage(content);
      }
    });
  }, [latestMessage?.content, latestMessage?.type]);
  
  return (
    <div
      className={activeContact ? "chat-box active" : "chat-box"}
      onClick={() => openUserChat(userId, userDetails)}
    >
      <div className="img-box">
        <img className="img-cover" src={userDetails?.photo} alt="" />
      </div>
      <div className="chat-details">
        <div className="text-head">
          <h4>{userDetails?.name}</h4>
          <p className="time unread">
            {latestMessage?.timestamp &&
              convertTimestamp(latestMessage?.timestamp)}
          </p>
        </div>
        {latestMessage && (
          <div className="text-message inline-message">
            {latestMessage?.type === 33
              ? "Sticker"
              : finalMessage?.length > 20
              ? removeHtmlTags(finalMessage).slice(0, 30).includes("/image")
                ? "Image"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/video")
                ? "Video"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/audio/")
                ? "sent an audio file"
                : removeHtmlTags(finalMessage).slice(0, 30).includes("/file/")
                ? "sent a file"
                : `${removeHtmlTags(finalMessage).slice(0, 30)}...`
              : removeHtmlTags(finalMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
