export function convertTimestamp(timestamp: any) {
  const now: any = new Date(); // Current date and time
  const messageDate: any = new Date(timestamp); // Date from the timestamp

  // Check if the message date is the same as the current date
  if (
    messageDate.getDate() === now.getDate() &&
    messageDate.getMonth() === now.getMonth() &&
    messageDate.getFullYear() === now.getFullYear()
  ) {
    // Message was sent today, show "Today" and the time
    return `Today ${messageDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  // Calculate the time difference in milliseconds
  const timeDifference = now - messageDate;

  // // Check if the message date was yesterday
  // if (
  //   timeDifference >= 24 * 60 * 60 * 1000 &&
  //   timeDifference < 2 * 24 * 60 * 60 * 1000
  // ) {
  //   // Message was sent yesterday, show "Yesterday" and the time
  //   return `Yesterday ${messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  // }

  // Check other conditions and format the date accordingly
  if (timeDifference < 24 * 60 * 60 * 1000) {
    // Less than 24 hours ago, show time
    return `Yesterday ${messageDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    // return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else if (timeDifference < 7 * 24 * 60 * 60 * 1000) {
    // Less than 7 days ago, show day of the week
    return messageDate.toLocaleString("en-US", { weekday: "long" });
  } else {
    // 7 days or more ago, show date in the format DD/MM/YYYY
    const day = messageDate.getDate();
    const month = messageDate.getMonth() + 1; // Months are zero-based
    const year = messageDate.getFullYear();
    return `${day}/${month < 10 ? "0" : ""}${month}/${year}`;
  }
}
export function timestampToTime(timestamp: any) {
  const now: any = new Date(); // Current date and time
  const messageDate: any = new Date(timestamp); // Date from the timestamp
  return `${messageDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
}

export function getTextFromHTMLString(htmlString) {
  return (
    document
      .createRange()
      // Creates a fragment and turns the supplied string into HTML nodes
      .createContextualFragment(htmlString)
      // Gets the text from the fragment
      .textContent // Removes the Zero-Width Space, Zero-Width Joiner, Zero-Width No-Break Space, Left-To-Right Mark, and Right-To-Left Mark characters
      .replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, "")
      // Trims off any extra space on either end of the string
      .trim()
      .toString()
  );
}

export const getFileSizeKB = (fileSize: number): string => {
  const fileSizeInKB = (fileSize / 1024).toFixed(2);
  return `${fileSizeInKB} KB`;
};

export const checkUrlType = (finalMessage: any) => {
  if (finalMessage?.includes("http:") || finalMessage?.includes("https:")) {
    return `<a href="${finalMessage}">${finalMessage}</a>`;
  } else {
    return finalMessage;
  }
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};
