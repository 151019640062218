/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import "../styles/chat.css";
import Window from "../components/Chat/Window";
import { } from "../_contexts/VideoCallContext"
import { ChatProvider } from "../_contexts/ChatContext";
const Chat = () => {
  return (
    <>
    <ChatProvider> 
      <Window />
      </ChatProvider>
    </>
  );
}
export default Chat;