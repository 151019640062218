import { authenticateFirebase, database } from "../../_utils/firebase";
import axios from "axios";
import { server } from "../../config/config";
import {
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  get,
  equalTo,
  update,
} from "firebase/database";
import {
  ref as sref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../_utils/firebase";
import { AnyAction } from "@reduxjs/toolkit";
interface UserData {
  customToken: string;
  // Other properties from the response
}

// interface AuthRequestAction {
//   type: 'authRequest';
// }

// interface AuthSuccessAction {
//   type: 'authSuccess';
//   payload: UserData;
// }

// interface AuthFailureAction {
//   type: 'authFailure';
//   payload: string;
// }

// interface CurrentChatAction {
//   type: 'currentChat';
//   payload: {
//     userId: string;
//     name: string;
//     photo: string;
//     userType: string;
//     // Other properties from the currentChatObj
//   };
// }

// type AuthActionTypes = AuthRequestAction | AuthSuccessAction | AuthFailureAction | CurrentChatAction ;

export const getAuthenticated =
  (identifier: string) => async (dispatch: any) => {
    try {
      dispatch({ type: "authRequest" });

      const { data } = await axios.get<UserData>(
        `${server}/users/getAuthenticatedUser`,
        {
          params: {
            identifier: identifier,
          },
        }
      );

      await authenticateFirebase(data.customToken);
     
      dispatch({ type: "authSuccess", payload: data });
    } catch (error: any) {
      dispatch({ type: "authFailure", payload: error.message });
    }
  };

export const updateProfilePicture =
  (userId: any, file: any) => async (dispatch: any) => {
    try {
      
      dispatch({ type: "updateRequest" });
      if (!file) return;
      const uniqueFileName = `${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 10)}`;
      const storageRef = sref(storage, `/image_profile/${uniqueFileName}`);
      // const storageRef = sref(storage, `/image_profile/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
         
        },
        // (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        
            dispatch(updateUserProfile(userId, { photo: url }));
          });
        }
      );
    } catch (error) {
      dispatch({ type: "updateFailure", payload: error.message });
    }
  };

export const updateUserProfile =
  (userId: any, userData: any) => async (dispatch: any) => {
    try {
      dispatch({ type: "updateRequest" });
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, userData);
      const updatedSnapshot = await get(userRef);
      dispatch({ type: "updateSuccess", payload: updatedSnapshot.val() });
    } catch (error) {
      dispatch({ type: "updateFailure", payload: error.message });
    }
  };

export const setCurrentChat =
  (currentChatObj: {
    userId: string;
    name: string;
    photo: string;
    userType: string;
    // Other properties from the currentChatObj
  }) =>
  (dispatch: any) => {
    dispatch({ type: "currentChat", payload: { ...currentChatObj } });
  };

//CALLS
// Action type for creating an outgoing call
export const createOutgoingCall =
  (currentCallObj: {
    toId: string;
    callerId: string;
    name: string;
    photo: string;
    channel: string; 
    type: string;
  }) =>
  (dispatch: any) => {
    dispatch({ type: "currentCall", payload: { ...currentCallObj } });
  };

// Action type for creating an outgoing call
export const setAllMessagesInRedux =
  (reduxAllMessages: any) => (dispatch: any) => {
    dispatch({ type: "reduxAllMessages", payload: { ...reduxAllMessages } });
  };

export const setAllGroupMessagesInRedux =
  (reduxAllGroupMessages: any) => (dispatch: any) => {
    dispatch({
      type: "reduxAllGroupMessages",
      payload: { ...reduxAllGroupMessages },
    });
  };

export const createOutGoingGroupCall =
  (currentCallObj: any[]) => (dispatch: any) => {
    dispatch({ type: "currentGroupCall", payload: { ...currentCallObj } });
  };
export const clearGroupCallDetails = () => (dispatch: any) => {
  dispatch({ type: "currentGroupCall", payload: null });
};
export const setAnsweredGroupCall =
  (currentCallIdObj: any) => (dispatch: any) => {
    dispatch({ type: "currentGroupCall", payload: { ...currentCallIdObj } });
  };

//All type of incoming call
export const createIncomingCall =
  (currentCallObj: {
    toId: string;
    callerId: string;
    name: string;
    photo: string;
    channel: string; // channel
    type: string;
  }) =>
  (dispatch: any) => {
    dispatch({ type: "currentCall", payload: { ...currentCallObj } });
  };

// Action type for setting the current outgoing call ID
export const setCurrentOutgoingCallId =
  (currentCallIdObj: { callId: string }) => (dispatch: any) => {
    dispatch({ type: "currentCall", payload: { ...currentCallIdObj } });
  };
//Cleare call objet
export const clearCallDetails = (currentCallIdObj: {}) => (dispatch: any) => {
  dispatch({ type: "currentCall", payload: { ...currentCallIdObj } });
};

// THIS IS TEMP CHAT WINDOW DATA

export const setTempChatWindowData =
  (currentChatObj: any[]) => (dispatch: any) => {
    dispatch({ type: "tempChatData", payload: { ...currentChatObj } });
  };

  // ANSWERED CALLS

export const setAnsweredCall =
  (currentCallIdObj: { status: string; callNature: string }) =>
  (dispatch: any) => {
    dispatch({ type: "currentCall", payload: { ...currentCallIdObj } });
  };
