/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  getAllContacts,
  getContacts,
  getGroups,
  requestUserPermission,
} from "../../_utils/firebase";
import { useChatContext } from "../../_contexts/ChatContext";
import { IncomingCallIndicator } from "./chatScreen/IncomingCallIndicator";
import { useVideoCallContext } from "../../_contexts/VideoCallContext";
// import { setTempChatWindowData } from "../../redux/actions/user";
import { useAppSelector, useAppDispatch } from "../../redux/Hooks";
import ContactsList from "./contacts/ContactsList";
import ChatScreen from "./chatScreen/ChatScreen";
import Profile from "./Profile";
import { logOutUser } from "../../_services/logout";
import { useMeetingContext } from "../../_contexts/MeetingContext";

const Window: React.FC = () => {
  const identifier = localStorage.getItem("sessionIdentifier");
  const [userContacts, setUserContacts] = useState<any[]>([]);
  const [contactLoading, setContactLoading] = useState(true);
  const { uid, photo } = useSelector((state: any) => state.user);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const { initChat } = useChatContext();
  const [search, setSearch] = useState<string>("");
  const [filteredContacts, setFilteredContacts] = useState<any[]>([]);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { handelMeetingStep, setMeetingName } = useMeetingContext();
  const { handelIncomingCallFirebaseNodeListner, handelSetContactList } =
    useVideoCallContext();
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  const meetingCode = searchParams.get("meeting");

 
  // const dispatch = useAppDispatch();
  // const { data } = useAppSelector(state => state.tempChatWindowData)
  // let [queryString, setQueryString] = useSearchParams();

  // const dropdownRef = useRef<HTMLUListElement>(null);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
  //       setIsDisplayed(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isDisplayed]);

  const toggleDisplay = () => {
    setIsDisplayed(!isDisplayed);
  };

  const logOut = () => {
    let logOut = logOutUser(localStorage.getItem("sessionIdentifier"));

    if (logOut) {
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        window.location.href = "/nz-chat-web";
      }, 300);
    }
  };

  const viewProfile = () => {
    setShowProfile(true);
  };

  useEffect(() => {
    meetingCode && window.open(`/meeting?meeting=${meetingCode}`, "_parent");
  }, []);

  const allUserMessages = async (uid: any) => {
    await requestUserPermission(identifier);
    await initChat(uid);
  };

  const getContactsAndGroups = async (uid: any) => {
    try {
      const [users, groups] = await Promise.all([
        getContacts(uid),
        getGroups(uid),
      ]);
      setContactLoading(false);
      const contacts = [...users, ...groups];
      setUserContacts(contacts);
      handelSetContactList(contacts);
      //TODO::I use it later
      // dispatch(setTempChatWindowData(contacts));
    } catch (error) {
      console.error("Error fetching contacts and groups:", error);
    }
  };

  useEffect(() => {
    // const redirectType = queryString.get("redirect");
    getAllContacts("HOOExJ8HVEXROkSxRA77xJ5j73v1");
    allUserMessages(uid);
    getContactsAndGroups(uid);
    handelIncomingCallFirebaseNodeListner();
  }, [uid]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    const filtered = userContacts.filter((contact) =>
      contact?.info?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredContacts(filtered);
  };

  const displayUsers =
    filteredContacts.length > 0 ? filteredContacts : userContacts;

  return (
    <>
      {<IncomingCallIndicator />}
      {isDisplayed}
      <div
        className="main-container"
        id="chat__wrapper"
        onClick={() => {
          isDisplayed && setIsDisplayed(false);
        }}
      >
        <div className="left-container">
          <div className="header">
            <div className="user-img" onClick={() => setShowProfile(true)}>
              <img className="dp" src={photo} alt="" />
            </div>
            <ul className="nav-icons">
              <li>
                <Link to="/meeting">
                  <i
                    className="ri-team-fill"
                    onClick={() => {
                      handelMeetingStep(1);
                      setMeetingName("");
                    }}
                    title="Meeting"
                  ></i>
                </Link>
              </li>
              <li>
                <i className={`ri-broadcast-line`} title="Broadcast"></i>
              </li>
              <li>
                <i className="ri-more-2-fill" onClick={toggleDisplay}></i>
                <ul
                  className={`fadeInDown text-center dropDownMenu fade ${
                    isDisplayed ? "show" : "hide"
                  }`}
                  id="logoutDrop"
                  style={{ display: isDisplayed ? "block" : "none" }}
                >
                  <li onClick={viewProfile}>Profile</li>
                  <li onClick={logOut}>Log out</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="search-container">
            <div className="input">
              <i className="ri-search-line"></i>
              <input
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search or start new chat"
              />
            </div>
          </div>
          {search.length === 0 ||
          (search.length > 0 && filteredContacts.length > 0) ? (
            <>
              <ContactsList
                displayUsers={displayUsers}
                contactLoading={contactLoading}
              />
            </>
          ) : (
            <>
              <div className="chat-list">
                <p className="no-data-found">no data found</p>
              </div>
            </>
          )}

          <div className="chat-bottom">
            <img className="app-logo" src="images/app_logo.png" alt="" />
            <p>@ 2024 Nzchat App. All rights reserved</p>
          </div>
          {showProfile && (
            <div className="modal-container">
              <Profile setShowProfile={setShowProfile} />
            </div>
          )}
        </div>
        <ChatScreen />
      </div>
    </>
  );
};

export default Window;
