/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React,{useEffect} from "react";
import { useVideoCallContext } from "../../../_contexts/VideoCallContext";
import {CALL_STATUS, CALL_TYPE} from "../../../_utils";
import { useSelector } from "react-redux";
export const IncomingCallIndicator = () => {
  const {handelEndIncomingCallToFirebase, handelPickIncomingCall , handelCallUpdateListner} = useVideoCallContext();
  const { uid, name,photo,status,type,callType,callerId} = useSelector((state: any) => state.currentCall);
  const currentCall =  useSelector((state: any) => state.currentCall);

useEffect(()=>{
    handelCallUpdateListner()
},[type])

  return (
    <>
     {/* && incomingCallObject.callerId !== uid */}
      {status === CALL_STATUS.Ringing && type === CALL_TYPE.Incoming && callerId !== uid &&
        <div className="upper-strip">
          <div className="user-info">
            <img src={photo} />
            <div className="user-detail">
              <h6>{name}</h6>
              <span>Incoming call...</span>
            </div>
          </div>
          <div className="icon-box-section">
            {(callType === 4 || callType === 2) && (
              <span><i className="ri-vidicon-line" onClick={()=>{handelPickIncomingCall(currentCall)}}></i></span>
            )}
            {/* {(incomingCallObject.callType === 1 || incomingCallObject.callType === 3) && (
              <span onClick={() => { handelJoinCall(incomingCallObject.channel);}}><i className="ri-phone-line"></i></span>
            )} */}
            <span onClick={handelEndIncomingCallToFirebase} className="call-end"><i className="ri-phone-line"></i></span>
          </div>
        </div>
      }
    </>
  );
};